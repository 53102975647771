import { Filters } from "@nerdjs/nerd-core";
import { NerdAdvancedSearch, useQuery } from "@nerdjs/nerd-ui";
import { AdvancedSearchDefinition } from "@nerdjs/nerd-ui/dist/nerdAdvancedSearch/types";
import { ReactElement, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  openInvoiceFiltersSelector,
  openInvoiceSearchOpenSelector,
} from "../../redux/openInvoice/openInvoiceSelectors";
import { useAppSelector } from "../hooks";
import {
  setOpenInvoiceFilters,
  setOpenInvoiceSearchOpen,
} from "../../redux/openInvoice/openInvoiceSlice";
import { useLazyGetOpenInvoicesQuery } from "../../redux/network";
/**
 *
 * @returns {ReactElement} OpenInvoiceSearch page
 */
export function OpenInvoiceSearch(): ReactElement {
  const dispatch = useDispatch();
  const searchOpen = useAppSelector(openInvoiceSearchOpenSelector);
  const query = useQuery();
  const filtersString = query.get("filters");
  const filters: Filters = filtersString ? JSON.parse(filtersString) : [];
  const filtersInRedux = useAppSelector(openInvoiceFiltersSelector);
  const [getOpenInvoices, { isFetching }] = useLazyGetOpenInvoicesQuery();
  const navigate = useNavigate();

  useEffect(() => {
    //We restore the filters from redux if they exists
    if (filtersInRedux && filters.length === 0) {
      query.set("filters", JSON.stringify(filtersInRedux));
      navigate({ search: query.toString() });
    }
  }, []);

  useEffect(() => {
    // we save the filters in redux whenever they change
    if (filtersString) {
      const filters = JSON.parse(filtersString);
      dispatch(setOpenInvoiceFilters(filters));
    } else dispatch(setOpenInvoiceFilters(undefined));
  }, [filtersString]);

  const definition: AdvancedSearchDefinition[] = [
    {
      id: "customer_id",
      name: "invoices.customer_id",
      label: "Customer ID",
      type: "int",
      faIcon: "fa-regular fa-hashtag",
    },
  ];

  return (
    <NerdAdvancedSearch
      definitions={definition}
      open={searchOpen}
      setOpen={(o) => dispatch(setOpenInvoiceSearchOpen(o))}
      itemsPerPage={25}
      hidePresets
      refreshPending={isFetching}
      onRefresh={(q) => getOpenInvoices(q)}
    />
  );
}
