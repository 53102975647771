import { Filters } from "@nerdjs/nerd-core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Invoice_Entity } from "../../entities";

export interface OpenInvoiceState {
  stagedOpenInvoice: Invoice_Entity | undefined;
  selectedOpenInvoiceID: number | undefined;
  searchOpen: boolean;
  filters?: Filters;
}

const initialState: OpenInvoiceState = {
  searchOpen: false,
  selectedOpenInvoiceID: undefined,
  stagedOpenInvoice: undefined,
};

const openInvoiceSlice = createSlice({
  name: "openInvoice",
  initialState,
  reducers: {
    setOpenInvoiceSearchOpen(state, action: PayloadAction<boolean>) {
      state.searchOpen = action.payload;
    },
    setOpenInvoiceFilters(state, action: PayloadAction<Filters | undefined>) {
      state.filters = action.payload;
    },
    setStagedOpenInvoice(
      state,
      action: PayloadAction<Invoice_Entity | undefined>
    ) {
      state.stagedOpenInvoice = action.payload;
    },
    setSelectedOpenInvoiceID(state, action: PayloadAction<number | undefined>) {
      state.selectedOpenInvoiceID = action.payload;
    },
  },
});

export const {
  setOpenInvoiceFilters,
  setOpenInvoiceSearchOpen,
  setSelectedOpenInvoiceID,
  setStagedOpenInvoice,
} = openInvoiceSlice.actions;

export default openInvoiceSlice.reducer;
