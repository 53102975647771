import { configureStore } from "@reduxjs/toolkit";
import { customerPortalApi } from "./api/customerPortalApi";

const store = configureStore({
  reducer: {
    [customerPortalApi.reducerPath]: customerPortalApi.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
