import { useTheme } from "@mui/joy";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import Autocomplete from "@mui/joy/Autocomplete";
import FormControl from "@mui/joy/FormControl";
import { ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RouterConfig } from "../config/routerConfig";
import { useAppSelector } from "../hooks";
import {
  getActiveLoadsSelectors,
  getArchivedLoadsSelectors,
} from "../../redux/network";
import { Load_Entity } from "../../entities";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { useMediaQuery } from "@mui/material";

/**
 *
 * @returns {ReactElement} QuickTrack
 */
export function QuickTrack(): ReactElement {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const ref = useRef<HTMLInputElement | null>(null);
  const params = {
    limit: "25",
    offset: "0",
    filters: null,
  };
  const selectAllActiveLoads = getActiveLoadsSelectors(params).selectAll;
  const selectAllArchivedLoads = getArchivedLoadsSelectors(params).selectAll;
  const activeLoads = useAppSelector((state) => selectAllActiveLoads(state));
  const { data: currentUser } = useGetCurrentUserQuery();
  const archivedLoads = useAppSelector((state) =>
    selectAllArchivedLoads(state)
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleChange = (value: string | Load_Entity | null) => {
    if (value !== null) {
      if (typeof value !== "string") {
        redirect(value.id);
      } else {
        redirect(value.replace(/\D/g, ""));
      }
    }
  };

  /**
   *
   * @param {string | number} id Load ID
   */
  function redirect(id: string | number) {
    setInputValue("");
    ref.current?.blur();
    console.log(currentUser);
    if (currentUser) {
      navigate(`${RouterConfig.activeShipments}/${id}`);
    } else {
      navigate(`${RouterConfig.quickTrack}/${id}`);
    }
  }

  return (
    <FormControl>
      <Autocomplete
        sx={{
          width: downMd ? 180 : undefined,
        }}
        variant="soft"
        size="sm"
        color="neutral"
        placeholder={currentUser ? t("Load Search") : t("Quick Track")}
        open={inputValue.length > 0 && open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          if (typeof option !== "string") return option.id.toString();
          else return option;
        }}
        options={activeLoads.concat(archivedLoads)}
        startDecorator={<i className="fa-solid fa-box-open"></i>}
        endDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
        renderOption={(props, option) => (
          <AutocompleteOption
            {...props}
            sx={{ zIndex: 999 }}
          >{`#${option.id} (${option.refNumber})`}</AutocompleteOption>
        )}
        forcePopupIcon={false}
        inputValue={inputValue}
        freeSolo
        onInputChange={(_e, value) => setInputValue(value)}
        onChange={(_e, value) => handleChange(value)}
        slotProps={{
          input: {
            ref: ref,
          },
        }}
      />
    </FormControl>
  );
}
