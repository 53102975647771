import { Box, useMediaQuery } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useAppSelector } from "../hooks";
import { NerdPaginationFooter, useQuery } from "@nerdjs/nerd-ui";
import { Chip, Typography, useTheme } from "@mui/joy";
import { DateTime } from "luxon";
import {
  getQuotesSelectors,
  useGetQuotesQuery,
  useLazyGetLoadQuery,
} from "../../redux/network";
import { Quote, Quote_Entity } from "../../entities";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { useNavigate } from "react-router-dom";
import { RouterConfig } from "../config/routerConfig";

const DATA_GRID_STATE_KEY = "customer-portal-quotes-datagrid-state";

/**
 *
 * @returns {ReactElement} QuotesDataGrid component
 */
export function QuotesDataGrid() {
  const { t } = useTranslation("entities");
  const apiRef = useGridApiRef();
  const query = useQuery();
  const filters = query.get("filters");
  const limit = query.get("limit");
  const offset = query.get("offset");
  const [key, setKey] = useState(0);
  const params = {
    filters: filters,
    offset: offset !== null ? offset : "0",
    limit: limit !== null ? limit : "25",
  };
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [getLoad, { isFetching: isFetching2 }] = useLazyGetLoadQuery();
  const navigate = useNavigate();
  const selectAllQuotes = getQuotesSelectors(params).selectAll;
  const { isFetching } = useGetQuotesQuery(params);
  const columns: GridColDef<Quote_Entity>[] = useMemo(
    () => [
      {
        field: "id",
        headerName: `${t("quote.entity")} ${t("quote.attributes.id")}`,
        width: 150,
      },
      {
        field: "createdAt",
        headerName: t("quote.attributes.createdAt"),
        width: 200,
        renderCell(params) {
          const date = DateTime.fromISO(params.value);
          return date.isValid ? date.toFormat("ff") : "";
        },
      },
      {
        field: "type",
        headerName: t("quote.attributes.type"),
        width: 50,
      },
      {
        field: "pickupCity",
        headerName: t("quote.others.pickup"),
        width: 200,
        valueGetter(params) {
          if (params.rowNode.type !== "group") {
            const stop = new Quote(params.row).getPickupStop();
            return `${stop?.city || ""}${
              stop?.state || stop?.city ? "," : ""
            } ${stop?.state || ""}`;
          }
        },
        groupingValueGetter(params) {
          const stop = new Quote(params.row).getPickupStop();
          return `${stop?.city || ""}${stop?.state || stop?.city ? "," : ""} ${
            stop?.state || ""
          }`;
        },
        renderCell(params) {
          return (
            <Typography
              fontSize={"inherit"}
              fontWeight={"inherit"}
              noWrap
              sx={{
                color: "inherit",
              }}
            >
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: "deliveryCity",
        headerName: t("quote.others.delivery"),
        width: 200,
        valueGetter(params) {
          if (params.rowNode.type !== "group") {
            const stop = new Quote(params.row).getDeliveryStop();
            return `${stop?.city || ""}${
              stop?.state || stop?.city ? "," : ""
            } ${stop?.state || ""}`;
          }
        },
        groupingValueGetter(params) {
          const stop = new Quote(params.row).getDeliveryStop();
          return `${stop?.city || ""}${stop?.state || stop?.city ? "," : ""} ${
            stop?.state || ""
          }`;
        },
        renderCell(params) {
          return (
            <Typography
              fontSize={"inherit"}
              fontWeight={"inherit"}
              noWrap
              sx={{
                color: "inherit",
              }}
            >
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: "expirationDate",
        headerName: t("quote.others.expiration"),
        width: 100,
        valueGetter(params) {
          return params.row.createdAt;
        },
        groupingValueGetter(params) {
          return params.row.createdAt;
        },
        renderCell(params) {
          const date = DateTime.fromISO(params.value);
          const remainingTime =
            5 - Math.floor(DateTime.now().diff(date, "hours").hours);
          return (
            <Chip
              size="sm"
              color={
                remainingTime >= 4
                  ? "success"
                  : remainingTime >= 2
                  ? "warning"
                  : "danger"
              }
            >
              {remainingTime >= 1
                ? t("entities:quote.others.remaining_time", {
                    count: remainingTime,
                  })
                : t("entities:quote.others.expired")}
            </Chip>
          );
        },
      },
      {
        field: "loadId",
        headerName: t("entities:load.entity"),
        width: 200,
        groupingValueGetter(params) {
          return params.row.loadId || "No Shipment";
        },
        renderCell(params) {
          if (params.rowNode.type !== "group") {
            if (params.value)
              return (
                <Chip
                  endDecorator={<i className="fa-solid fa-chevron-right"></i>}
                  size="sm"
                  variant="soft"
                  color="neutral"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (params.value)
                      getLoad(params.value)
                        .unwrap()
                        .then((load) => {
                          const route =
                            load.status === "Delivered"
                              ? `${RouterConfig.arrivedShipments}/${load.id}`
                              : `${RouterConfig.activeShipments}/${load.id}`;
                          navigate(route);
                        })
                        .catch(() => {
                          return;
                        });
                  }}
                >
                  {`${t("entities:load.others.open_shipment")} #${
                    params.value
                  }`}
                </Chip>
              );
            else return <></>;
          } else return params.value;
        },
      },
    ],
    []
  );

  const rows = useAppSelector((state) => selectAllQuotes(state));
  const columnsState = localStorage.getItem(DATA_GRID_STATE_KEY);
  const initialState: GridInitialStatePremium = useMemo(() => {
    try {
      return columnsState
        ? JSON.parse(columnsState)
        : {
            pinnedColumns: isDownMd
              ? {}
              : { right: ["expirationDate"], left: ["id"] },
          };
    } catch {
      return {
        pinnedColumns: isDownMd
          ? {}
          : { right: ["expirationDate"], left: ["id"] },
      };
    }
  }, [columnsState]);

  const resetTableSettings = () => {
    localStorage.removeItem(DATA_GRID_STATE_KEY);
    setKey((k) => k + 1);
  };

  const saveState = useCallback(() => {
    localStorage.setItem(
      DATA_GRID_STATE_KEY,
      JSON.stringify(apiRef.current.exportState())
    );
  }, [apiRef]);

  return (
    <DataGridPremium
      key={key}
      initialState={initialState}
      onStateChange={saveState}
      rows={rows}
      loading={isFetching || isFetching2}
      columns={columns}
      apiRef={apiRef}
      slots={{
        toolbar: CustomToolbar,
        footer: CustomFooter,
      }}
      onRowClick={(params) =>
        navigate(`${RouterConfig.quote}/${params.row.id}`)
      }
      slotProps={{
        toolbar: { resetTableSettings },
        footer: { isFetching, count: rows.length },
      }}
    />
  );
}

/**
 *
 * @param {object} props props
 * @param {Function} props.resetTableSettings resetTableSettings
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar({
  resetTableSettings,
}: {
  resetTableSettings: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        p: 1,
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* <LoadSearch /> */}
      <Box />
      <Chip
        color="neutral"
        size="sm"
        startDecorator={<i className="fa-solid fa-rotate-right"></i>}
        onClick={resetTableSettings}
      >
        {t("Reset Columns Layout")}
      </Chip>
    </Box>
  );
}

/**
 *
 * @param {object} props Component props
 * @param {boolean} props.isFetching is DataGrid loading
 * @param {number} props.count Rows number
 * @returns {ReactElement} Custom Footer
 */
function CustomFooter(props: { isFetching: boolean; count: number }) {
  const { count, isFetching } = props;
  return <NerdPaginationFooter count={count} loading={isFetching} />;
}
