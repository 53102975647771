import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { Invoice_Entity } from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const invoicesAdapter = createEntityAdapter<Invoice_Entity>();
const invoicesInitialState = invoicesAdapter.getInitialState();

const openInvoicesAdapter = createEntityAdapter<Invoice_Entity>();
const openInvoicesInitialState = openInvoicesAdapter.getInitialState();

export const invoiceEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getInvoices: build.query<
      EntityState<Invoice_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `invoices`,
          params: args,
        };
      },
      transformResponse: (responseData: Invoice_Entity[]) => {
        return invoicesAdapter.setAll(invoicesInitialState, responseData);
      },
      providesTags: ["invoices"],
    }),
    getOpenInvoices: build.query<
      EntityState<Invoice_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `companies/openInvoices`,
          params: args,
        };
      },
      transformResponse: (responseData: Invoice_Entity[]) => {
        return openInvoicesAdapter.setAll(
          openInvoicesInitialState,
          responseData
        );
      },
      providesTags: ["openInvoices"],
    }),
    getInvoice: build.query<Invoice_Entity, number>({
      query: (id) => `invoices/${id}`,
    }),
    createInvoice: build.mutation<Invoice_Entity, Partial<Invoice_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `invoices`,
      }),
      invalidatesTags: ["invoices"],
    }),
    updateInvoice: build.mutation<
      void,
      { id: number; body: Partial<Invoice_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `invoices/${args.id}`,
      }),
      invalidatesTags: ["invoices"],
    }),
    deleteInvoice: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `invoices/${id}`,
      }),
      invalidatesTags: ["invoices"],
    }),
    getInvoiceSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `invoices/searchMetadata`,
    }),
  }),
});

export const {
  useGetInvoiceQuery,
  useGetOpenInvoicesQuery,
  useGetInvoicesQuery,
  useGetInvoiceSearchMetadataQuery,
  useLazyGetInvoicesQuery,
  useLazyGetInvoiceQuery,
  useLazyGetInvoiceSearchMetadataQuery,
  useLazyGetOpenInvoicesQuery,
  useCreateInvoiceMutation,
  useDeleteInvoiceMutation,
  useUpdateInvoiceMutation,
} = invoiceEndpoints;

export default invoiceEndpoints;

export const getInvoicesSelectors = (
  params: Record<string, unknown> | void
) => {
  return invoicesAdapter.getSelectors<RootState>((state) => {
    return (
      createSelector(
        invoiceEndpoints.endpoints.getInvoices.select(params),
        (invoicesResult) => {
          return invoicesResult.data;
        }
      )(state) ?? invoicesInitialState
    );
  });
};

export const getOpenInvoicesSelectors = (
  params: Record<string, unknown> | void
) => {
  return openInvoicesAdapter.getSelectors<RootState>((state) => {
    return (
      createSelector(
        invoiceEndpoints.endpoints.getOpenInvoices.select(params),
        (invoicesResult) => {
          return invoicesResult.data;
        }
      )(state) ?? openInvoicesInitialState
    );
  });
};
