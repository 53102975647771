import { useTranslation } from "react-i18next";
import { LastLocation_Entity, Load_Entity } from "../../entities";
import { Card, Tooltip, Typography } from "@mui/joy";
import { Box, Paper } from "@mui/material";
import { DateTime } from "luxon";

type LoadLastLocationProps =
  | {
      anonymous: true;
      load: LastLocation_Entity;
    }
  | {
      anonymous?: false;
      load: Load_Entity;
    };

/**
 *
 * @param {LoadLastLocationProps} props props
 * @returns {ReactElement} LoadLastLocation page
 */
export function LoadLastLocation(props: LoadLastLocationProps) {
  const { load, anonymous } = props;
  const { t } = useTranslation("entities");
  const lastlocation = anonymous ? load : load.lastLocation;

  return (
    <Card
      sx={{
        p: 2,
      }}
    >
      <Typography level="title-md">{t("Last Location")}</Typography>
      <Paper sx={{ p: 2, display: "flex", gap: 2, mt: 1 }} variant="outlined">
        <i
          style={{ fontSize: 18, marginTop: 12 }}
          className="fa-solid fa-truck"
        ></i>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
          }}
        >
          {lastlocation ? (
            <Box>
              <Typography>{`${lastlocation.city}, ${lastlocation.state}`}</Typography>
              <Typography level="body-sm">
                {DateTime.fromISO(
                  lastlocation.dateLocal.replace(" ", "T")
                ).toFormat("MM/dd/yy hh:mm a")}{" "}
                ({t("Local Time")})
              </Typography>
            </Box>
          ) : (
            <Typography>{t("No Location Found")}</Typography>
          )}
          <Tooltip title={t("Open in Google Maps")} arrow>
            <Box
              sx={{
                borderRadius: 16,
                overflow: "hidden",
                cursor: "pointer",
                border: "solid 1px rgba(155,155,155,0.3)",
                "&hover:": {
                  boxShadow: "0px 2px 4px rgba(155,155,155)",
                },
              }}
            >
              <img
                alt="google-maps"
                style={{
                  width: 80,
                  height: 80,
                }}
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/?q=${lastlocation?.latitude},${lastlocation?.longitude}`,
                    "_blank"
                  )
                }
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${lastlocation?.latitude},${lastlocation?.longitude}&zoom=15&size=200x230&maptype=roadmap&markers=color:red%7C${lastlocation?.latitude},${lastlocation?.longitude}&key=AIzaSyCx8q4t7MKbDWoof2CfvtDp9tFgH0adOzw`}
              />
            </Box>
          </Tooltip>
        </Box>
      </Paper>
    </Card>
  );
}
