import { ShipCon, ShipCon_Entity } from "../shipCon";
import { File } from "../file";
import { LastLocation } from "../lastLocation";
import { Accessorial } from "../accessorial";

/**
 * Load_Entity type guard.
 *
 * @param {any} loadJson Load object from API
 * @returns {boolean} Return true if type is Load_Entity
 */
function validator(loadJson: unknown): loadJson is Load_Entity {
  if (typeof loadJson === "object" && loadJson != null) {
    Load_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(loadJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class Load_Entity {
  static requiredFields = [
    "id",
    "refNumber",
    "quoteId",
    "totalMiles",
    "pallets",
    "feet",
    "weight",
    "type",
    "status",
    "stops",
    "lastLocation",
    "serviceLevel",
    "reefer",
    "total",
    "baseRate",
    "fuelSurcharge",
    "tendered",
  ];

  constructor(loadJson: unknown) {
    if (validator(loadJson)) {
      this.id = loadJson.id;
      this.refNumber = loadJson.refNumber;
      this.quoteId = loadJson.quoteId;
      this.totalMiles = loadJson.totalMiles;
      this.pallets = loadJson.pallets;
      this.feet = loadJson.feet;
      this.weight = loadJson.weight;
      this.type = loadJson.type;
      this.status = loadJson.status;
      this.stops = loadJson.stops;
      this.lastLocation = loadJson.lastLocation;
      this.files = loadJson.files;
      this.accessorials = loadJson.accessorials;
      this.serviceLevel = loadJson.serviceLevel;
      this.reefer = loadJson.reefer;
      this.total = loadJson.total;
      this.baseRate = loadJson.baseRate;
      this.fuelSurcharge = loadJson.fuelSurcharge;
      this.tendered = loadJson.tendered;
      this.invoiceDate = loadJson.invoiceDate;
      this.apiTenderID = loadJson.apiTenderID;
    } else {
      throw new Error(`Failed to create new instance of ${Load_Entity.name}`);
    }
  }

  id: number;
  apiTenderID: number;
  refNumber: string;
  quoteId: number;
  totalMiles: number;
  pallets: number;
  feet: number;
  weight: number;
  type: string;
  status: string;
  stops: Array<ShipCon_Entity>;
  lastLocation: LastLocation;
  files?: Array<File>;
  accessorials?: Array<Accessorial>;
  serviceLevel: string;
  reefer: boolean;
  total: number;
  baseRate: number;
  fuelSurcharge: number;
  tendered: boolean;
  invoiceDate?: string;
}
