/**
 * ShipCon_Entity type guard.
 *
 * @param {any} shipConJson ShipCon object from API
 * @returns {boolean} Return true if type is ShipCon_Entity
 */
function validator(shipConJson: unknown): shipConJson is ShipCon_Entity {
  if (typeof shipConJson === "object" && shipConJson != null) {
    ShipCon_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(shipConJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class ShipCon_Entity {
  static requiredFields = [
    "type",
    "name",
    "city",
    "state",
    "zip",
    "stopNumber",
    "eta",
    "hasETA",
    "latitude",
    "longitude",
  ];

  constructor(shipConJson: unknown) {
    if (validator(shipConJson)) {
      this.type = shipConJson.type;
      this.name = shipConJson.name;
      this.city = shipConJson.city;
      this.state = shipConJson.state;
      this.zip = shipConJson.zip;
      this.date = shipConJson.date;
      this.timeFrom = shipConJson.timeFrom;
      this.timeTo = shipConJson.timeTo;
      this.stopNumber = shipConJson.stopNumber;
      this.eta = shipConJson.eta;
      this.hasETA = shipConJson.hasETA;
      this.latitude = shipConJson.latitude;
      this.longitude = shipConJson.longitude;
    } else {
      throw new Error(
        "Failed to create new instance of ${ ShipCon_Entity.name }: ${e}"
      );
    }
  }

  type: string;
  name: string;
  city: string;
  state: string;
  zip: string;
  date?: string;
  timeFrom?: string;
  timeTo?: string;
  stopNumber: number;
  eta: ETA_Entity;
  hasETA: boolean;
  latitude: string;
  longitude: string;
}

interface ETA_Entity {
  arrived: boolean;
  arrivedAt?: string;
  arrivedAtUtc?: string;
  departed: boolean;
  departedAt?: string;
  departedAtUtc?: string;
}
