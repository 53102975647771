import { Clear, FilePresent } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  Typography,
  useTheme,
} from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import { NerdFileUpload } from "@nerdjs/nerd-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Load } from "../../entities";
import { useUploadFileMutation } from "../../redux/network";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { AppConfig } from "../../environement";

/**
 *
 * @returns {ReactElement} LoadLastLocation page
 */
export function LoadFiles({ load }: { load: Load }) {
  const { t } = useTranslation("entities");
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [file, setFile] = useState<File>();
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [description, setDescription] = useState<string | null>();
  const handleOpenUploadDialog = () => {
    setOpenUploadDialog(true);
  };
  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setFile(undefined);
    setDescription(undefined);
  };

  const RATE_CONFIRMATION = "Rate Confirmation";
  const PICKUP_DOCUMENT = "Pickup document";
  const MISCELLANEOUS = "Miscellaneous";

  const handleUpload = () => {
    if (description && file) uploadFile({ id: load.id, description, file });
  };

  return (
    <Card
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography level="title-md">{t("Files")}</Typography>
      <Button
        disabled={load.status === "Cancelled" ? true : false}
        variant="soft"
        onClick={handleOpenUploadDialog}
      >
        Upload Files
      </Button>
      <DataGridPremium
        autoHeight
        density="compact"
        rows={load.files ?? []}
        onRowClick={(r) => {
          const a = encodeURI(
            `${AppConfig.api.endpoint}${AppConfig.api.routePrefix}/loads/${load.id}/files/${r.row.id}`
          );
          window.open(a, "_blank", "noopener,noreferrer");
        }}
        columns={[{ field: "description", flex: 1 }]}
      />
      <Dialog
        open={openUploadDialog}
        onClose={handleCloseUploadDialog}
        fullWidth={isLoading ? false : true}
        fullScreen={fullScreen}
      >
        {!isLoading ? (
          <>
            <DialogTitle>{t("load.others.upload_document")}</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70%",
                }}
              >
                <div>
                  {file !== undefined ? (
                    <div>
                      <IconButton
                        sx={{
                          position: "relative",
                          left: "75%",
                          top: 20,
                        }}
                        variant="plain"
                        color="danger"
                        onClick={() => setFile(undefined)}
                      >
                        <Clear />
                      </IconButton>
                      <Typography
                        textAlign={"center"}
                        level="h1"
                        color="primary"
                      >
                        <FilePresent />
                      </Typography>
                      <Typography textAlign={"center"}>{file.name}</Typography>
                      <Typography textAlign={"center"} level="body-sm">
                        {`${(file.size / 1000000).toFixed(2)} MB`}
                      </Typography>
                    </div>
                  ) : (
                    <>
                      <NerdFileUpload uploadFile={(file) => setFile(file)} />
                      <Typography textAlign={"center"} level="body-sm">
                        {t("common:file_upload.or_drag_and_drop")}
                      </Typography>
                    </>
                  )}
                </div>
              </Box>
              <FormControl required>
                <FormLabel>{t("common:file_upload.document_type")}</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={description}
                  exclusive
                  onChange={(_e, value) => setDescription(value)}
                  aria-label={t("common:file_upload.document_type")}
                  size="small"
                >
                  <ToggleButton value={RATE_CONFIRMATION}>
                    {t("load.others.rate_confirmation")}
                  </ToggleButton>
                  <ToggleButton value={PICKUP_DOCUMENT}>
                    {t("load.others.pickup_document_bol")}
                  </ToggleButton>
                  <ToggleButton value={MISCELLANEOUS}>
                    {t("load.others.miscellaneous")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleCloseUploadDialog}>
                {t("common:global.cancel")}
              </Button>
              <Button
                disabled={description === null || file === undefined}
                onClick={handleUpload}
              >
                {t("common:global.upload")}
              </Button>
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        )}
      </Dialog>
    </Card>
  );
}
