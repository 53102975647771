import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { Location_Entity } from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const locationsAdapter = createEntityAdapter<Location_Entity>();
const locationsInitialState = locationsAdapter.getInitialState();

export const locationEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query<
      EntityState<Location_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `locations`,
          params: args,
        };
      },
      serializeQueryArgs: ({ endpointDefinition, endpointName }) =>
        defaultSerializeQueryArgs({
          queryArgs: {},
          endpointDefinition,
          endpointName,
        }),
      transformResponse: (responseData: Location_Entity[]) => {
        return locationsAdapter.setAll(locationsInitialState, responseData);
      },
      providesTags: ["locations"],
    }),
    getLocation: build.query<Location_Entity, number>({
      query: (id) => `locations/${id}`,
    }),
    createLocation: build.mutation<Location_Entity, Partial<Location_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `locations`,
      }),
      invalidatesTags: ["locations"],
    }),
    updateLocation: build.mutation<
      void,
      { id: number; body: Partial<Location_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `locations/${args.id}`,
      }),
      invalidatesTags: ["locations"],
    }),
    deleteLocation: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `locations/${id}`,
      }),
      invalidatesTags: ["locations"],
    }),
    getLocationSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `locations/searchMetadata`,
    }),
  }),
});

export const {
  useGetLocationQuery,
  useGetLocationsQuery,
  useGetLocationSearchMetadataQuery,
  useLazyGetLocationsQuery,
  useLazyGetLocationQuery,
  useLazyGetLocationSearchMetadataQuery,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useUpdateLocationMutation,
} = locationEndpoints;

export default locationEndpoints;

export const selectLocationsResult =
  locationEndpoints.endpoints.getLocations.select();

const selectLocationsData = createSelector(
  selectLocationsResult,
  (locationsResult) => {
    return locationsResult.data;
  }
);

export const { selectAll: selectAllLocations, selectById: selectLocationById } =
  locationsAdapter.getSelectors<RootState>((state) => {
    return selectLocationsData(state) ?? locationsInitialState;
  });
