import Quote_Entity, { Stop_Entity } from "./quote";

export default class QuoteRequest extends Quote_Entity {
  static fromList(quoteJSON: unknown): Array<QuoteRequest> {
    const quotes: QuoteRequest[] = [];
    if (quoteJSON)
      Array.isArray(quoteJSON) &&
        quoteJSON.forEach((quoteJSON) => {
          quotes.push(new QuoteRequest(quoteJSON));
        });
    return quotes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  private getStop(stopType: "consignee" | "shipper"): Stop_Entity | undefined {
    const stop = this.stops.find((stop) => stop.type === stopType);
    return stop;
  }

  getPickupStop() {
    return this.getStop("shipper");
  }

  getDeliveryStop() {
    return this.getStop("consignee");
  }
}
