import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { Contact_Entity } from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const contactsAdapter = createEntityAdapter<Contact_Entity>();
const contactsInitialState = contactsAdapter.getInitialState();

export const contactEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getContacts: build.query<
      EntityState<Contact_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `contacts`,
          params: args,
        };
      },
      serializeQueryArgs: ({ endpointDefinition, endpointName }) =>
        defaultSerializeQueryArgs({
          queryArgs: {},
          endpointDefinition,
          endpointName,
        }),
      transformResponse: (responseData: Contact_Entity[]) => {
        return contactsAdapter.setAll(contactsInitialState, responseData);
      },
      providesTags: ["contacts"],
    }),
    getContact: build.query<Contact_Entity, number>({
      query: (id) => `contacts/${id}`,
    }),
    createContact: build.mutation<Contact_Entity, Partial<Contact_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `contacts`,
      }),
      invalidatesTags: ["contacts"],
    }),
    updateContact: build.mutation<
      void,
      { id: number; body: Partial<Contact_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `contacts/${args.id}`,
      }),
      invalidatesTags: ["contacts"],
    }),
    deleteContact: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `contacts/${id}`,
      }),
      invalidatesTags: ["contacts"],
    }),
    getContactSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `contacts/searchMetadata`,
    }),
  }),
});

export const {
  useGetContactQuery,
  useGetContactsQuery,
  useGetContactSearchMetadataQuery,
  useLazyGetContactsQuery,
  useLazyGetContactQuery,
  useLazyGetContactSearchMetadataQuery,
  useCreateContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation,
} = contactEndpoints;

export default contactEndpoints;

export const selectContactsResult =
  contactEndpoints.endpoints.getContacts.select();

const selectContactsData = createSelector(
  selectContactsResult,
  (contactsResult) => {
    return contactsResult.data;
  }
);

export const { selectAll: selectAllContacts, selectById: selectContactById } =
  contactsAdapter.getSelectors<RootState>((state) => {
    return selectContactsData(state) ?? contactsInitialState;
  });
