import { Box } from "@mui/material";
import { NerdUserMenu } from "@nerdjs/account-kit";
import { ReactElement, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MyCompany } from "../hooks/company/myCompany";

/**
 * @returns {ReactElement} MyAccountRoute
 */
export default function MyAccountRoute(): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTabIndex = Number(searchParams.get("index")) || 0;
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);

  useEffect(() => {
    setTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);

  return (
    <Box width={"100%"} height="100%">
      <NerdUserMenu
        tabIndex={tabIndex}
        onTabIndexChange={(i) => {
          if (i !== null) {
            setSearchParams({ index: i.toString() });
            setTabIndex(Number(i));
          }
        }}
        customTabLabels={["My Company"]}
        customTabs={[<MyCompany key={"customTab1"} />]}
        customerPortal={true}
      />
    </Box>
  );
}
