import { Box, CircularProgress } from "@mui/joy";
import { QuoteTemplate } from "@nerdjs/sales-kit";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RouterConfig } from "../hooks/config/routerConfig";
import { useGetQuoteQuery, useLazyGetLoadQuery } from "../redux/network";

/**
 *
 * @returns {JSX.Element} Users route
 */
export function QuoteRoute(): JSX.Element {
  const navigate = useNavigate();
  const { quoteID } = useParams();
  const id = useMemo(() => Number(quoteID), []);
  const { data: quote, isFetching } = useGetQuoteQuery(id, {
    skip: isNaN(id) ? true : false,
  });
  const [getLoad, { data: load, isFetching: isFetching2, error }] =
    useLazyGetLoadQuery();

  useEffect(() => {
    if (quote && quote.loadId) {
      getLoad(quote.loadId);
    }
  }, [quote]);

  if (
    isFetching ||
    isFetching2 ||
    (!isFetching && quote?.loadId && !load && !error)
  )
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  else
    return (
      <QuoteTemplate
        openLoadLink={
          load
            ? load.status === "Delivered"
              ? `${RouterConfig.arrivedShipments}/${load.id}`
              : `${RouterConfig.activeShipments}/${load.id}`
            : undefined
        }
        quoteRoute={RouterConfig.quote}
        initialResult={
          quote
            ? {
                ...quote,
                weight: quote?.weight || 0,
                transitTime: quote.transitTime || 0,
              }
            : undefined
        }
        customerPortal={true}
        handleOnTenderLoadClick={(quoteID, selectedServiceLevel) =>
          navigate(
            `${RouterConfig.tender}/${quoteID}?selected=${selectedServiceLevel}`
          )
        }
      />
    );
}
