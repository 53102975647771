import { DarkMode, LightMode } from "@mui/icons-material";
import { Button, IconButton } from "@mui/joy";
import * as React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { Hidden } from "@mui/material";
import { useColorScheme as useJoyColorScheme } from "@mui/joy/styles";
import { useColorScheme as useMaterialColorScheme } from "@mui/material/styles";

/**
 * @component
 * @returns {React.ReactElement} Button to trigger the dark/light mode. Should be placed in the NerdAppBar configuration
 */
export function NerdAppBarDarkModeButton(): React.ReactElement {
  const { mode, setMode: setMaterialMode } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();
  const { t } = useTranslation();

  const isDark = mode === "dark";

  /**
   * Handle click on DarkMode/LightMode button
   */
  function handleClick() {
    isDark ? setJoyMode("light") : setJoyMode("dark");
    isDark ? setMaterialMode("light") : setMaterialMode("dark");
  }

  return (
    <>
      <Hidden smUp>
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          onClick={handleClick}
        >
          {isDark ? (
            <LightMode fontSize="inherit" />
          ) : (
            <DarkMode fontSize="inherit" />
          )}
        </IconButton>
      </Hidden>
      <Hidden smDown>
        <Button
          onClick={handleClick}
          variant="plain"
          color="neutral"
          size="sm"
          startDecorator={
            isDark ? (
              <LightMode fontSize="inherit" />
            ) : (
              <DarkMode fontSize="inherit" />
            )
          }
        >
          <Hidden smDown>
            {!isDark ? t("global.dark_mode") : t("global.light_mode")}
          </Hidden>
        </Button>
      </Hidden>
    </>
  );
}
