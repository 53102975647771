import { useState, useEffect } from "react";

export interface NerdMarkerProps extends google.maps.MarkerOptions {
  onClick?: () => void;
}

/**
 * Marker that can be used with NerdMap component.
 *
 * @param {NerdMarkerProps} props Component props
 * @returns {null} The component returns null as google.maps.Map manages the DOM manipulation.
 */
export function NerdMarker(props: NerdMarkerProps) {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [onClickListener, setOnClickListener] =
    useState<google.maps.MapsEventListener>();
  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(props);
      if (props.onClick) {
        onClickListener?.remove();
        setOnClickListener(marker.addListener("click", props.onClick));
      }
    }
  }, [marker, props]);

  return null;
}
