import {
  Receipt,
  Check,
  Attachment,
  AttachMoney,
  ListAlt,
} from "@mui/icons-material";
import { ReactElement } from "react";

/**
 *
 * @param {string} description File description
 * @returns {ReactElement} Icon
 */
export function getFileIcon(description: string): ReactElement {
  switch (description) {
    case "POD":
      return <Receipt />;
    case "Rate Confirmation":
      return <Check />;
    case "Miscellaneous":
      return <Attachment />;
    case "Invoice":
      return <AttachMoney />;
    default:
      return <ListAlt />;
  }
}
