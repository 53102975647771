import { createSelector } from "@reduxjs/toolkit";
import { Invoice } from "../../entities";
import { RootState } from "../store";

const selectOpenInvoiceFilters = (state: RootState) =>
  state.openInvoiceState.filters;
const selectOpenInvoiceSearchOpen = (state: RootState) =>
  state.openInvoiceState.searchOpen;
const selectSelectedOpenInvoiceID = (state: RootState) =>
  state.openInvoiceState.selectedOpenInvoiceID;
const selectStageOpenInvoiceSelector = (state: RootState) =>
  state.openInvoiceState.stagedOpenInvoice;

export const stagedOpenInvoiceSelector = createSelector(
  [selectStageOpenInvoiceSelector],
  (s) => (s ? new Invoice(s) : s)
);

export const selectedOpenInvoiceIDSelector = createSelector(
  [selectSelectedOpenInvoiceID],
  (s) => s
);

export const openInvoiceSearchOpenSelector = createSelector(
  [selectOpenInvoiceSearchOpen],
  (s) => s
);

export const openInvoiceFiltersSelector = createSelector(
  [selectOpenInvoiceFilters],
  (s) => s
);
