import { AnyAction, Dispatch, Middleware } from "redux";
import { store } from "./store";
import { showNotification } from "@nerdjs/nerd-ui";
import { AppConfig } from "../environement";

export const mainMiddleware: Middleware =
  () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    if (action.type == "nerdNetworkSlice/setNerdNetworkApiError") {
      const {
        uuid,
        status,
        description = "",
        message = "",
      } = action.payload.lastApiError;

      if (status === 401) {
        window.location.replace(AppConfig.front["401Redirect"]);
      }
      store.dispatch(
        showNotification({
          title: `${status}`.concat(description ? ` - ${description}` : ""),
          body: message,
          severity: status > 300 && status < 500 ? "warning" : "danger",
          autohide: true,
          error: {
            uuid,
            url: action.payload.originalError.meta.baseQueryMeta.request.url,
            method:
              action.payload.originalError.meta.baseQueryMeta.request.method,
            args: action.payload.originalError.meta.arg.originalArgs,
            endpointName: action.payload.originalError.meta.arg.endpointName,
            error: action.payload.originalError.payload,
          },
        })
      );
    }

    return next(action);
  };
