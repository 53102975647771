import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppStatusState {
  formOpen: Record<string, boolean>;
}

const initialState: AppStatusState = {
  formOpen: {},
};

const appStatusSlice = createSlice({
  name: "appStatus",
  initialState,
  reducers: {
    setFormOpen(
      state,
      action: PayloadAction<{ open: boolean; formID: string }>
    ) {
      state.formOpen[action.payload.formID] = action.payload.open;
    },
  },
});

export const { setFormOpen } = appStatusSlice.actions;

export default appStatusSlice.reducer;
