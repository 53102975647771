import { useColorScheme } from "@mui/joy";
import { Box } from "@mui/material";
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { mapOptions } from "../config/mapOptions";
import { mapOptionsDarkMode } from "../config/mapOptionsDarkMode";
import { NerdMarkerProps } from "../nerdMarker/nerdMarker";

/**
 *
 * @param {google.maps.MapOptions} props Map options
 * @returns {ReactElement} Map
 */
export function NerdMap(
  props: PropsWithChildren<
    google.maps.MapOptions & {
      onZoomChange: (zoom: number) => void;
    }
  >
): ReactElement {
  const { onZoomChange, zoom, center, children } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { mode } = useColorScheme();
  const isDark = mode === "dark";
  const [map, setMap] = useState<google.maps.Map>();
  const [markers, setMarkers] = useState<ReactElement<NerdMarkerProps>[]>();

  useEffect(() => {
    if (map) {
      if (isDark) {
        map.setOptions({ styles: mapOptionsDarkMode.styles });
      } else {
        map.setOptions({ styles: mapOptions.styles });
      }
    }
  }, [isDark]);

  useEffect(() => {
    if (map && center != undefined) {
      map?.setCenter(center);
    }
  }, [center]);

  useEffect(() => {
    if (map && zoom != undefined) {
      map?.setZoom(zoom);
    }
  }, [zoom]);

  useEffect(() => {
    if (ref.current && !map) {
      initMap();
    }
  }, [ref, map]);

  useEffect(() => {
    initMap();
  }, [children]);

  const initMap = () => {
    if (ref.current) {
      const m = new window.google.maps.Map(ref.current, {
        ...props,
        ...(isDark ? mapOptionsDarkMode : mapOptions),
      });
      const _markers: ReactElement<NerdMarkerProps>[] = [];
      const bounds = new google.maps.LatLngBounds();
      Children.map(children, (child) => {
        if (isValidElement(child)) {
          const _child = child as ReactElement<NerdMarkerProps>;
          if (_child.props.position) bounds.extend(_child.props.position);
          _markers.push(
            cloneElement(child as ReactElement<NerdMarkerProps>, {
              ...child.props,
              map: m,
            })
          );
        }
      });
      if (_markers.length > 1) m.fitBounds(bounds);
      setMarkers(_markers);
      setMap(m);
    }
  };

  map?.addListener("zoom_changed", () => {
    const zoom = map.getZoom();

    if (zoom != undefined) onZoomChange(zoom);
  });

  return (
    <Box sx={{ flexGrow: 1, height: "100%", borderRadius: 1 }} ref={ref}>
      {markers}
    </Box>
  );
}
