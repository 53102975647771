import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { Company_Entity } from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const companiesAdapter = createEntityAdapter<Company_Entity>();
const companiesInitialState = companiesAdapter.getInitialState();

export const companyEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getcompanies: build.query<
      EntityState<Company_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `companies`,
          params: args,
        };
      },
      serializeQueryArgs: ({ endpointDefinition, endpointName }) =>
        defaultSerializeQueryArgs({
          queryArgs: {},
          endpointDefinition,
          endpointName,
        }),
      transformResponse: (responseData: Company_Entity[]) => {
        return companiesAdapter.setAll(companiesInitialState, responseData);
      },
      providesTags: ["companies"],
    }),
    getCompany: build.query<Company_Entity, number>({
      query: (id) => `companies/${id}`,
    }),
    getCurrentCompany: build.query<Company_Entity, void>({
      query: () => `companies/current`,
    }),
    createCompany: build.mutation<Company_Entity, Partial<Company_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `companies`,
      }),
      invalidatesTags: ["companies"],
    }),
    updateCompany: build.mutation<
      void,
      { id: number; body: Partial<Company_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `companies/${args.id}`,
      }),
      invalidatesTags: ["companies"],
    }),
    deleteCompany: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `companies/${id}`,
      }),
      invalidatesTags: ["companies"],
    }),
    getcompaniesearchMetadata: build.query<SearchMetadata, void>({
      query: () => `companies/searchMetadata`,
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useGetcompaniesQuery,
  useGetcompaniesearchMetadataQuery,
  useLazyGetcompaniesQuery,
  useLazyGetCompanyQuery,
  useGetCurrentCompanyQuery,
  useLazyGetCurrentCompanyQuery,
  useLazyGetcompaniesearchMetadataQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
} = companyEndpoints;

export default companyEndpoints;

export const selectcompaniesResult =
  companyEndpoints.endpoints.getcompanies.select();

const selectcompaniesData = createSelector(
  selectcompaniesResult,
  (companiesResult) => {
    return companiesResult.data;
  }
);

export const { selectAll: selectAllcompanies, selectById: selectCompanyById } =
  companiesAdapter.getSelectors<RootState>((state) => {
    return selectcompaniesData(state) ?? companiesInitialState;
  });
