import Invoice_Entity from "./invoice";
export default class Invoice extends Invoice_Entity {
  static fromList(invoicesJSON: unknown): Array<Invoice> {
    const invoices: Invoice[] = [];
    if (invoicesJSON)
      Array.isArray(invoicesJSON) &&
        invoicesJSON.forEach((invoiceJSON) => {
          invoices.push(new Invoice(invoiceJSON));
        });
    return invoices;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
