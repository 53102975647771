import { createSelector } from "@reduxjs/toolkit";
import { Load } from "../../entities";
import { RootState } from "../store";

const selectArchivedLoadFilters = (state: RootState) =>
  state.archivedLoadState.filters;
const selectArchivedLoadSearchOpen = (state: RootState) =>
  state.archivedLoadState.searchOpen;
const selectSelectedArchivedLoadID = (state: RootState) =>
  state.archivedLoadState.selectedArchivedLoadID;
const selectStageArchivedLoadSelector = (state: RootState) =>
  state.archivedLoadState.stagedArchivedLoad;

export const stagedArchivedLoadSelector = createSelector(
  [selectStageArchivedLoadSelector],
  (s) => (s ? new Load(s) : s)
);

export const selectedArchivedLoadIDSelector = createSelector(
  [selectSelectedArchivedLoadID],
  (s) => s
);

export const archivedLoadSearchOpenSelector = createSelector(
  [selectArchivedLoadSearchOpen],
  (s) => s
);

export const archivedLoadFiltersSelector = createSelector(
  [selectArchivedLoadFilters],
  (s) => s
);
