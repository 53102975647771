import { Card, Divider, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Load } from "../../entities";
import { Grid } from "@mui/material";
import { currencyFormatter } from "../../helpers/accounting/accounting";

/**
 *
 * @returns {ReactElement} LoadLastLocation page
 */
export function LoadCost({ load }: { load: Load }) {
  const { t } = useTranslation("entities");

  return (
    <Card
      sx={{
        p: 2,
      }}
    >
      <Typography level="title-md">{t("Load Cost")}</Typography>
      <Grid
        container
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ mt: 1 }}
      >
        {load.accessorials && (
          <>
            <Grid item xs={12}>
              {load.accessorials?.map((accessorial) => (
                <Grid container key={accessorial.id}>
                  <Grid item xs={6}>
                    <Typography level="title-md">{accessorial.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography textAlign={"right"} level={"body-sm"}>
                      {currencyFormatter.format(accessorial.costAmount)}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography level={"body-sm"}>
                {t("load.others.base_rate")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={"right"} level={"body-sm"}>
                {currencyFormatter.format(load.baseRate)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography level={"body-sm"}>
                {t("load.others.fuel_surcharge")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={"right"} level={"body-sm"}>
                {currencyFormatter.format(load.fuelSurcharge)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography level={"body-sm"}>
                {t("load.attributes.total")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={"right"} level={"body-sm"}>
                {currencyFormatter.format(load.total)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
