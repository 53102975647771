import { SearchOff } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Link, Typography } from "@mui/joy";
import { useParams } from "react-router-dom";
import { LoadDetails } from "../hooks/load/loadDetails";
import { Link as RouterLink } from "react-router-dom";
import { RouterConfig } from "../hooks/config/routerConfig";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { useQuicktrackQuery } from "../redux/network";
import { LastLocation_Entity, Load_Entity } from "../entities";

/**
 *
 * @returns {ReactElement | null} QuickTrackRoute
 */
export function QuickTrackRoute(): ReactElement | null {
  const { t } = useTranslation("entities");
  const { loadID } = useParams();
  if (loadID !== undefined) {
    const {
      data: load,
      isFetching,
      isError,
    } = useQuicktrackQuery(Number(loadID));
    const { data: currentUser } = useGetCurrentUserQuery();
    if (isError)
      return (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          width={"100%"}
        >
          <Grid textAlign={"center"}>
            <Typography level={"h4"}>
              {`${t("load.others.load_not_found")}.`}
            </Typography>
            <Typography level={"h1"} mt={2} color="danger">
              <SearchOff />
            </Typography>
            {!currentUser ? (
              <Typography>
                {t("load.others.load_not_found_message_anonymous_user")}
              </Typography>
            ) : (
              <Typography>
                {`${t("load.others.load_not_found_message")}, `}
                <Link component={RouterLink} to={RouterConfig.contacts}>
                  {`${t("load.others.contact_us")}.`}
                </Link>
              </Typography>
            )}
          </Grid>
          <Grid textAlign={"center"}></Grid>
        </Grid>
      );
    else if (isFetching)
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    else if (load && currentUser)
      return <LoadDetails load={load as Load_Entity} anonymous={false} />;
    else if (load && !currentUser)
      return (
        <LoadDetails load={load as LastLocation_Entity} anonymous={true} />
      );
    else return null;
  } else return null;
}
