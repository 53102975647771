import { Box, Grid, Typography, Divider, CircularProgress } from "@mui/joy";
import { Paper } from "@mui/material";
import { NerdClickToCopy } from "@nerdjs/nerd-ui";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useGetCurrentCompanyQuery } from "../../redux/network";

/**
 *
 * @returns {ReactElement} MyCompany
 */
export function MyCompany(): ReactElement {
  const { t } = useTranslation("entities");
  const { data: currentCompany, isFetching } = useGetCurrentCompanyQuery();
  if (isFetching)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  else
    return (
      <Box sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <Paper sx={{ padding: "10px" }}>
              <Typography display="block" level="title-md" gutterBottom>
                {t("company.others.address")}
              </Typography>
              <Divider />
              <Grid container>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>
                      {`${t("company.others.name")}: `}
                    </Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.name}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>
                      {`${t("company.others.address")}: `}
                    </Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.address}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>
                      {`${t("company.others.city")}: `}
                    </Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.city}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>{`${t(
                      "company.others.state"
                    )}: `}</Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.state}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>{`${t(
                      "company.others.zip_code"
                    )}: `}</Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.zipCode}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>{`${t(
                      "company.others.country"
                    )}: `}</Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.country}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid xs={12}>
            <Paper sx={{ padding: "10px" }}>
              <Typography display="block" level="title-md" gutterBottom>
                {t("company.others.contact")}
              </Typography>
              <Divider />
              <Grid container>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>
                      {`${t("company.others.email")}: `}
                    </Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.email}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>
                      {`${t("company.others.phone")}: `}
                    </Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.phone}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid xs={12}>
            <Paper sx={{ padding: "10px" }}>
              <Typography display="block" level="title-md" gutterBottom>
                {t("company.others.about")}
              </Typography>
              <Divider />
              <Grid container>
                <Grid xs={12}>
                  <Box display={"flex"}>
                    <Typography fontWeight={400}>
                      {`${t("company.others.customer_code")}: `}
                    </Typography>
                    <NerdClickToCopy typographyProps={{ fontWeight: 100 }}>
                      {currentCompany?.customerCode}
                    </NerdClickToCopy>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
}
