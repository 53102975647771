import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import {
  ArcherFile_Entity,
  LastLocation_Entity,
  Load_Entity,
} from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const activeLoadsAdapter = createEntityAdapter<Load_Entity>();
const activeLoadsInitialState = activeLoadsAdapter.getInitialState();
const archivedLoadsAdapter = createEntityAdapter<Load_Entity>();
const archivedLoadsInitialState = archivedLoadsAdapter.getInitialState();

export const loadEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getActiveLoads: build.query<
      EntityState<Load_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `loads/active`,
          params: args,
        };
      },
      transformResponse: (responseData: Load_Entity[]) => {
        return activeLoadsAdapter.setAll(activeLoadsInitialState, responseData);
      },
      providesTags: ["activeLoads"],
    }),
    getArchivedLoads: build.query<
      EntityState<Load_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `loads/archived`,
          params: args,
        };
      },
      transformResponse: (responseData: Load_Entity[]) => {
        return archivedLoadsAdapter.setAll(
          archivedLoadsInitialState,
          responseData
        );
      },
      providesTags: ["archivedLoads"],
    }),
    getLoad: build.query<Load_Entity, number | undefined>({
      query: (id) => `loads/${id}`,
      providesTags: ["load"],
    }),
    createLoad: build.mutation<Load_Entity, Partial<Load_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `loads`,
      }),
      invalidatesTags: ["load", "activeLoads", "archivedLoads"],
    }),
    updateLoad: build.mutation<
      void,
      { id: number; body: Partial<Load_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `loads/${args.id}`,
      }),
      invalidatesTags: ["load", "activeLoads", "archivedLoads"],
    }),
    deleteLoad: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `loads/${id}`,
      }),
      invalidatesTags: ["load", "activeLoads", "archivedLoads"],
    }),
    getLoadSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `loads/searchMetadata`,
    }),
    quicktrack: build.query<LastLocation_Entity | Load_Entity, number>({
      query: (id) => {
        return {
          url: `loads/${id}/quicktrack`,
        };
      },
    }),
    uploadFile: build.mutation<
      ArcherFile_Entity,
      { id: number; description: string; file: File }
    >({
      query: (args) => {
        const body = new FormData();
        body.append("description", args.description);
        body.append("file", args.file);
        return {
          method: "POST",
          body: body,
          url: `loads/${args.id}/upload`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: ["load", "activeLoads", "archivedLoads"],
    }),
  }),
});

export const {
  useGetLoadQuery,
  useGetActiveLoadsQuery,
  useGetLoadSearchMetadataQuery,
  useGetArchivedLoadsQuery,
  useLazyGetActiveLoadsQuery,
  useLazyGetLoadQuery,
  useLazyGetLoadSearchMetadataQuery,
  useLazyGetArchivedLoadsQuery,
  useQuicktrackQuery,
  useLazyQuicktrackQuery,
  useCreateLoadMutation,
  useDeleteLoadMutation,
  useUpdateLoadMutation,
  useUploadFileMutation,
} = loadEndpoints;

export default loadEndpoints;

export const getActiveLoadsSelectors = (
  params: Record<string, unknown> | void
) => {
  return activeLoadsAdapter.getSelectors<RootState>((state) => {
    return (
      createSelector(
        loadEndpoints.endpoints.getActiveLoads.select(params),
        (loadsResult) => {
          return loadsResult.data;
        }
      )(state) ?? activeLoadsInitialState
    );
  });
};

export const getArchivedLoadsSelectors = (
  params: Record<string, unknown> | void
) => {
  return archivedLoadsAdapter.getSelectors<RootState>((state) => {
    return (
      createSelector(
        loadEndpoints.endpoints.getArchivedLoads.select(params),
        (loadsResult) => {
          return loadsResult.data;
        }
      )(state) ?? archivedLoadsInitialState
    );
  });
};
