import { DateTime } from "luxon";
import ShipCon_Entity from "./shipCon";
export default class ShipCon extends ShipCon_Entity {
  static fromList(shipConsJSON: unknown): Array<ShipCon> {
    const shipCons: ShipCon[] = [];
    if (shipConsJSON)
      Array.isArray(shipConsJSON) &&
        shipConsJSON.forEach((shipConJSON) => {
          shipCons.push(new ShipCon(shipConJSON));
        });
    return shipCons;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  getTime(): string {
    let time = "";
    if (this.timeFrom && this.timeTo) {
      if (this.timeTo != this.timeFrom)
        time += `${DateTime.fromFormat(
          this.timeFrom.substring(0, this.timeFrom.length - 3),
          "HH:mm"
        ).toFormat("hh:mm a")} - ${DateTime.fromFormat(
          this.timeTo.substring(0, this.timeFrom.length - 3),
          "HH:mm"
        ).toFormat("hh:mm a")}`;
    } else if (this.timeFrom) {
      time += this.timeFrom.substring(0, this.timeFrom.length - 3);
    }
    return time;
  }
}
