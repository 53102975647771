import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { File_Entity } from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const filesAdapter = createEntityAdapter<File_Entity>();
const filesInitialState = filesAdapter.getInitialState();

export const fileEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getFiles: build.query<
      EntityState<File_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `files`,
          params: args,
        };
      },
      serializeQueryArgs: ({ endpointDefinition, endpointName }) =>
        defaultSerializeQueryArgs({
          queryArgs: {},
          endpointDefinition,
          endpointName,
        }),
      transformResponse: (responseData: File_Entity[]) => {
        return filesAdapter.setAll(filesInitialState, responseData);
      },
      providesTags: ["files"],
    }),
    getFile: build.query<File_Entity, number>({
      query: (id) => `files/${id}`,
    }),
    createFile: build.mutation<File_Entity, Partial<File_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `files`,
      }),
      invalidatesTags: ["files"],
    }),
    updateFile: build.mutation<
      void,
      { id: number; body: Partial<File_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `files/${args.id}`,
      }),
      invalidatesTags: ["files"],
    }),
    deleteFile: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `files/${id}`,
      }),
      invalidatesTags: ["files"],
    }),
    getFileSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `files/searchMetadata`,
    }),
  }),
});

export const {
  useGetFileQuery,
  useGetFilesQuery,
  useGetFileSearchMetadataQuery,
  useLazyGetFilesQuery,
  useLazyGetFileQuery,
  useLazyGetFileSearchMetadataQuery,
  useCreateFileMutation,
  useDeleteFileMutation,
  useUpdateFileMutation,
} = fileEndpoints;

export default fileEndpoints;

export const selectFilesResult = fileEndpoints.endpoints.getFiles.select();

const selectFilesData = createSelector(selectFilesResult, (filesResult) => {
  return filesResult.data;
});

export const { selectAll: selectAllFiles, selectById: selectFileById } =
  filesAdapter.getSelectors<RootState>((state) => {
    return selectFilesData(state) ?? filesInitialState;
  });
