import { ReactElement } from "react";
import { ContactsList } from "../hooks/contact/contactsList";

/**
 *
 * @returns {ReactElement} ContactsRoute
 */
export default function ContactsRoute(): ReactElement {
  return <ContactsList />;
}
