import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { useGetCurrentUserQuery, User } from "@nerdjs/account-kit";
import React, { PropsWithChildren, ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import ActiveLoadsListRoute from "./routes/activeLoadsListRoute";
import ArchivedLoadsListRoute from "./routes/archivedLoadsListRoute";
import ContactsRoute from "./routes/contactsRoute";
import { ActiveLoadDetailsRoute } from "./routes/activeLoadDetailsRoute";
import MyAccountRoute from "./routes/myAccountRoute";
import NotFoundRoute from "./routes/notFoundRoute";
import { ArchivedLoadDetailsRoute } from "./routes/archivedLoadDetailsRoute";
import { QuickTrackRoute } from "./routes/quickTrackRoute";
import OpenInvoicesListRoute from "./routes/openInvoicesListRoute";
import QuotesListRoute from "./routes/quotesListRoute";
import { QuoteRoute } from "./routes/quoteRoute";
import { TenderRoute } from "./routes/tenderRoute";
import { AppConfig } from "./environement";
import * as Sentry from "@sentry/react";

const BoxWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hideMarginTop",
})<{ hideMarginTop?: boolean }>(({ theme, hideMarginTop }) => ({
  marginTop:
    useMediaQuery(theme.breakpoints.down("sm")) || hideMarginTop
      ? "inherit"
      : "48px",
  background:
    theme.palette.mode !== "light"
      ? "rgba(0,0,0,0.9)"
      : "rgba(255,255,255,0.9)",
  flexGrow: 1,
  display: "flex",
  position: "relative",
}));

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  const { data: currentUser } = useGetCurrentUserQuery();
  return (
    <Routes>
      <Route
        path={RouterConfig.root}
        element={
          currentUser ? (
            <Navigate to={RouterConfig.activeShipments} />
          ) : (
            <RequireAuth>
              <BoxWrapper></BoxWrapper>
            </RequireAuth>
          )
        }
      />
      <Route
        path={RouterConfig.quote}
        element={
          <RequireAuth>
            <BoxWrapper>
              <QuoteRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={`${RouterConfig.quote}/:quoteID`}
        element={
          <RequireAuth>
            <BoxWrapper>
              <QuoteRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={RouterConfig.activeShipments}
        element={
          <RequireAuth>
            <BoxWrapper>
              <ActiveLoadsListRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={`${RouterConfig.activeShipments}/:loadID`}
        element={
          <RequireAuth>
            <BoxWrapper>
              <ActiveLoadDetailsRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={RouterConfig.arrivedShipments}
        element={
          <RequireAuth>
            <BoxWrapper>
              <ArchivedLoadsListRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={`${RouterConfig.arrivedShipments}/:loadID`}
        element={
          <RequireAuth>
            <BoxWrapper>
              <ArchivedLoadDetailsRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={`${RouterConfig.quickTrack}/:loadID`}
        element={
          <BoxWrapper>
            <QuickTrackRoute />
          </BoxWrapper>
        }
      />
      <Route
        path={RouterConfig.quotes}
        element={
          <RequireAuth>
            <BoxWrapper>
              <QuotesListRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={`${RouterConfig.tender}/:id`}
        element={
          <RequireAuth>
            <BoxWrapper>
              <TenderRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={RouterConfig.myAccount}
        element={
          <RequireAuth>
            <BoxWrapper>
              <MyAccountRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={RouterConfig.contacts}
        element={
          <BoxWrapper>
            <ContactsRoute />
          </BoxWrapper>
        }
      />
      <Route
        path={RouterConfig.accounting}
        element={
          <RequireAuth>
            <BoxWrapper>
              <OpenInvoicesListRoute />
            </BoxWrapper>
          </RequireAuth>
        }
      />
      <Route
        path={"*"}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <NotFoundRoute />
          </BoxWrapper>
        }
      />
    </Routes>
  );
}

/**
 * Route wrapper that redirects logged off users to the signin route
 *
 * @param {PropsWithChildren} props props
 * @returns {ReactElement | null} Return RequireAuth component
 */
function RequireAuth(props: PropsWithChildren): ReactElement | null {
  const { data: currentUser } = useGetCurrentUserQuery();

  if (!currentUser) {
    window.location.replace(AppConfig.front["401Redirect"]);
    return null;
  } else {
    Sentry.setUser({
      email: currentUser.email,
      id: currentUser.uuid,
      username: new User(currentUser).fullname(),
    });
    return <>{props.children}</>;
  }
}
