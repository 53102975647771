import { File } from "../file";

/**
 * Invoice_Entity type guard.
 *
 * @param {any} invoiceJson Invoice object from API
 * @returns {boolean} Return true if type is Invoice_Entity
 */
function validator(invoiceJson: unknown): invoiceJson is Invoice_Entity {
  if (typeof invoiceJson === "object" && invoiceJson != null) {
    Invoice_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(invoiceJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Invoice_Entity {
  static requiredFields = [
    "id",
    "invoiceID",
    "invoiceDate",
    "dueDate",
    "inputDate",
    "moneyDue",
    "status",
    "statusCode",
    "days",
    "referenceNumber",
    "customerID",
    "termsName",
    "termsID",
    "files",
  ];

  constructor(invoiceJson: unknown) {
    if (validator(invoiceJson)) {
      this.id = invoiceJson.id;
      this.invoiceID = invoiceJson.invoiceID;
      this.invoiceDate = invoiceJson.invoiceDate;
      this.dueDate = invoiceJson.dueDate;
      this.inputDate = invoiceJson.inputDate;
      this.moneyDue = invoiceJson.moneyDue;
      this.status = invoiceJson.status;
      this.statusCode = invoiceJson.statusCode;
      this.days = invoiceJson.days;
      this.referenceNumber = invoiceJson.referenceNumber;
      this.customerID = invoiceJson.customerID;
      this.termsName = invoiceJson.termsName;
      this.termsID = invoiceJson.termsID;
      this.files = invoiceJson.files;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Invoice_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  invoiceID: number;
  invoiceDate: string;
  dueDate: string;
  inputDate: string;
  moneyDue: number;
  status: string;
  statusCode: number;
  days: number;
  referenceNumber: string;
  customerID: number;
  termsName: string;
  termsID: number;
  files: Array<File>;
}
