import { Card, Grid, Link, Typography, CircularProgress } from "@mui/joy";
import { ReactElement, useMemo } from "react";
import { Contact_Entity } from "../../entities";
import { useGetContactsQuery } from "../../redux/network";
import { useAppSelector } from "../hooks";
import { selectAllContacts } from "../../redux/network/contact";

/**
 *
 * @returns {ReactElement} ContactsList
 */
export function ContactsList(): ReactElement {
  const { isFetching } = useGetContactsQuery();
  const contacts = useAppSelector((state) => selectAllContacts(state));
  const groupedContacts = useMemo(() => {
    return contacts.reduce<Record<string, Contact_Entity[]>>(function (
      r: Record<string, Contact_Entity[]>,
      a
    ) {
      r[a.department] = r[a.department] || [];
      r[a.department].push(a);
      return r;
    },
    Object.create(null));
  }, [contacts]);

  if (isFetching) {
    return (
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems={"center"}
        width="100%"
        height={"100%"}
      >
        <Grid>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} sx={{ p: 2, maxWidth: 600, margin: "auto" }}>
      {Object.entries(groupedContacts).map(([department, contacts]) => (
        <Grid key={department} xs={12}>
          <Card>
            <Typography level="title-lg" sx={{ mb: 0.5 }}>
              <b>{department}</b>
            </Typography>
            <Grid spacing={1} container>
              {contacts.map((contact) => (
                <Grid key={contact.id} xs={12} sx={{ mb: 2 }}>
                  <Typography fontSize="md" sx={{ mb: 0.5, fontWeight: 500 }}>
                    {contact.name}
                  </Typography>
                  <Typography
                    startDecorator={<i className="fa-solid fa-phone"></i>}
                    level="body-sm"
                    sx={{ mb: 0.5 }}
                  >
                    <Link href={`tel:${contact.phone}`}>{contact.phone}</Link>
                  </Typography>
                  <Typography
                    startDecorator={<i className="fa-solid fa-envelope"></i>}
                    level="body-sm"
                    sx={{ mb: 0.5 }}
                  >
                    <Link href={`mailto:${contact.phone}`}>
                      {contact.email}
                    </Link>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
