import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { Claim_Entity } from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const claimsAdapter = createEntityAdapter<Claim_Entity>();
const claimsInitialState = claimsAdapter.getInitialState();

export const claimEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getClaims: build.query<
      EntityState<Claim_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `claims`,
          params: args,
        };
      },
      serializeQueryArgs: ({ endpointDefinition, endpointName }) =>
        defaultSerializeQueryArgs({
          queryArgs: {},
          endpointDefinition,
          endpointName,
        }),
      transformResponse: (responseData: Claim_Entity[]) => {
        return claimsAdapter.setAll(claimsInitialState, responseData);
      },
      providesTags: ["claims"],
    }),
    getClaim: build.query<Claim_Entity, number>({
      query: (id) => `claims/${id}`,
    }),
    createClaim: build.mutation<Claim_Entity, Partial<Claim_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `claims`,
      }),
      invalidatesTags: ["claims"],
    }),
    updateClaim: build.mutation<
      void,
      { id: number; body: Partial<Claim_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `claims/${args.id}`,
      }),
      invalidatesTags: ["claims"],
    }),
    deleteClaim: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `claims/${id}`,
      }),
      invalidatesTags: ["claims"],
    }),
    getClaimSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `claims/searchMetadata`,
    }),
  }),
});

export const {
  useGetClaimQuery,
  useGetClaimsQuery,
  useGetClaimSearchMetadataQuery,
  useLazyGetClaimsQuery,
  useLazyGetClaimQuery,
  useLazyGetClaimSearchMetadataQuery,
  useCreateClaimMutation,
  useDeleteClaimMutation,
  useUpdateClaimMutation,
} = claimEndpoints;

export default claimEndpoints;

export const selectClaimsResult = claimEndpoints.endpoints.getClaims.select();

const selectClaimsData = createSelector(selectClaimsResult, (claimsResult) => {
  return claimsResult.data;
});

export const { selectAll: selectAllClaims, selectById: selectClaimById } =
  claimsAdapter.getSelectors<RootState>((state) => {
    return selectClaimsData(state) ?? claimsInitialState;
  });
