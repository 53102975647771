import { Box, CssBaseline, Grid, useMediaQuery } from "@mui/material";
import {
  NerdNotifier,
  NerdAppBar,
  NerdFileUploadWrapper,
  NerdConfirmProvider,
  NerdVersionChecker,
} from "@nerdjs/nerd-ui";
import { ReactElement } from "react";
import { CircularProgress, useColorScheme, useTheme } from "@mui/joy";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { appBarConfig } from "../config/appBarConfig";
import { MainMenu } from "../mainMenu/mainMenu";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const { mode } = useColorScheme();
  const { isFetching } = useGetCurrentUserQuery();

  return (
    <NerdNotifier hideReportButton={true}>
      <NerdFileUploadWrapper>
        <Box
          sx={{
            backgroundImage: `url("${AppConfig.app.rootBackground}") `,
            display: "flex",
            flex: 1,
            flexDirection: md ? "column" : "row",
          }}
        >
          <CssBaseline />
          {isFetching ? (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <NerdAppBar {...appBarConfig(mode, md)} />
              <MainMenu />
              <NerdVersionChecker
                versionCheckerEndpoint={AppConfig.api.versionCheckerEndpoint}
              />
              <NerdConfirmProvider>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    marginTop: "48px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <AppRouter />
                  {/* <NerdFeedback
                    user={
                      currentUser
                        ? {
                            name: new User(currentUser).fullname(),
                            email: currentUser.email,
                          }
                        : undefined
                    }
                    nerdFileUploadProps={{
                      browseFilesButtonProps: {
                        variant: "plain",
                      },
                      acceptedFormatsText: "Accepted formats: .png, .jpeg",
                      accept: ["image/png", "image/jpeg"],
                      maxSize: 5000000,
                    }}
                  /> */}
                </Box>
              </NerdConfirmProvider>
            </>
          )}
        </Box>
      </NerdFileUploadWrapper>
    </NerdNotifier>
  );
}
