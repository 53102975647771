import { Box, Skeleton, useMediaQuery } from "@mui/material";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { LastLocation_Entity, Load, Load_Entity } from "../../entities";
import { NerdMap } from "../nerdMap/nerdMap";
import { NerdMarker } from "../nerdMarker/nerdMarker";
import { Allotment } from "allotment";
import { Alert, Button, Chip, Divider, Typography, useTheme } from "@mui/joy";
import { LoadLastLocation } from "./loadLastLocation";
import { LoadDetailsStatus } from "./loadDetailsStatus";
import { LoadCost } from "./loadCost";
import { RouterConfig } from "../config/routerConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadFiles } from "./loadFiles";
import { useTranslation } from "react-i18next";
import { showNotification, useNerdConfirm } from "@nerdjs/nerd-ui";
import { useAppDispatch } from "../hooks";
import { nanoid } from "@reduxjs/toolkit";
import { useDeleteLoadMutation } from "../../redux/network";
import { AppConfig } from "../../environement";
import { useDeleteTenderMutation } from "@nerdjs/sales-kit";
import { Warning } from "@mui/icons-material";
import { Link } from "react-router-dom";

type LoadDetailsProps =
  | {
      anonymous: true;
      load: LastLocation_Entity;
    }
  | {
      anonymous?: false;
      load: Load_Entity;
    };

/**
 *
 * @param {LoadDetailsProps} props Component props
 * @returns {ReactElement} LoadDetails
 */
export function LoadDetails(props: LoadDetailsProps): ReactElement {
  const { load: _load, anonymous } = props;
  const { loadID } = useParams();
  const dispatch = useAppDispatch();
  const confirm = useNerdConfirm();
  const [center, setCenter] = useState({
    lat: 39.8097343,
    lng: -98.5556199,
  });
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const { t } = useTranslation();
  const load = useMemo(
    () => (_load && !anonymous ? new Load(_load) : undefined),
    []
  );
  const [zoom, setZoom] = useState(5);
  const [mobile, setMobile] = useState(false);
  const [deleteTender] = useDeleteTenderMutation();
  const markers = useMemo(() => {
    const markers: ReactElement[] = [];
    if (!anonymous) {
      load?.stops?.forEach((stop, i) => {
        const position = {
          lat: Number(stop.latitude),
          lng: Number(stop.longitude),
        };
        const icon = {
          path: "M215.7,499.2c51.3-64.2,168.3-219.8,168.3-307.2C384,86,298,0,192,0S0,86,0,192c0,87.4,117,243,168.3,307.2,12.3,15.3,35.1,15.3,47.4,0h0Z",
          fillColor: stop.type === "delivery" ? "#ff9800" : "#4caf50",
          fillOpacity: 1,
          anchor: new google.maps.Point(0, 510),
          strokeWeight: 0,
          scale: 0.08,
          labelOrigin: new google.maps.Point(200, 200),
        };
        const label = {
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: "18px",
          text: stop.stopNumber.toString(),
        };
        markers.push(
          <NerdMarker
            key={i}
            position={position}
            icon={icon}
            label={label}
            optimized={false}
            onClick={() =>
              handleMarkerClick(Number(stop.latitude), Number(stop.longitude))
            }
          />
        );
      });
      if (load?.lastLocation.city) {
        markers.push(
          <NerdMarker
            key={3}
            position={{
              lat: Number(load.lastLocation.latitude),
              lng: Number(load.lastLocation.longitude),
            }}
            icon={{
              path: "M215.7,499.2c51.3-64.2,168.3-219.8,168.3-307.2C384,86,298,0,192,0S0,86,0,192c0,87.4,117,243,168.3,307.2,12.3,15.3,35.1,15.3,47.4,0h0Z",
              fillColor: "#03a9f4",
              fillOpacity: 1,
              anchor: new google.maps.Point(0, 510),
              strokeWeight: 0,
              scale: 0.08,
              labelOrigin: new google.maps.Point(200, 200),
            }}
            optimized={false}
            onClick={() => {
              if (load.lastLocation)
                handleMarkerClick(
                  Number(load.lastLocation.latitude),
                  Number(load.lastLocation.longitude)
                );
            }}
          />
        );
      }
    } else {
      markers.push(
        <NerdMarker
          key={3}
          position={{
            lat: Number(_load?.latitude),
            lng: Number(_load?.longitude),
          }}
          icon={{
            path: "M215.7,499.2c51.3-64.2,168.3-219.8,168.3-307.2C384,86,298,0,192,0S0,86,0,192c0,87.4,117,243,168.3,307.2,12.3,15.3,35.1,15.3,47.4,0h0Z",
            fillColor: "#03a9f4",
            fillOpacity: 1,
            anchor: new google.maps.Point(0, 510),
            strokeWeight: 0,
            scale: 0.08,
            labelOrigin: new google.maps.Point(200, 200),
          }}
          optimized={false}
          onClick={() => {
            handleMarkerClick(
              Number(_load?.latitude),
              Number(_load?.longitude)
            );
          }}
        />
      );
    }
    return markers;
  }, [load]);

  const customerPortalLoadAllotmentSize = JSON.parse(
    localStorage.getItem("customerPortalLoadAllotment") ?? '["50%", "50%"]'
  );
  const navigate = useNavigate();

  /**
   * @param {number} lat Marker latitude
   * @param {number} lng Marker longitude
   */
  const handleMarkerClick = useCallback(
    (lat: number, lng: number) => {
      setCenter({ lat, lng });
      setZoom(10);
    },
    [zoom]
  );

  const handleAllotmentChange = (e: number[]) => {
    if (e[1] < 600) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    localStorage.setItem("customerPortalLoadAllotment", JSON.stringify(e));
  };
  const location = useLocation();

  const loadDetail = (
    <>
      {anonymous ? (
        <Box
          sx={{
            p: 2,
            height: "calc(100vh - 48px)",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            pb: 8,
          }}
        >
          <Alert startDecorator={<Warning />} color="warning">
            The quick track only shows the last known location. Sign in to get
            more details about this load.
            <Chip
              startDecorator={<i className="fa-solid fa-circle-user"></i>}
              onClick={() =>
                window.open(
                  `${AppConfig.front["401Redirect"]}/signin?redirectTo=${AppConfig.front.hostname}/quicktrack/${loadID}`,
                  "blank"
                )
              }
            >
              Sign In
            </Chip>
          </Alert>
          {_load.latitude ? (
            <LoadLastLocation anonymous={true} load={_load} />
          ) : (
            []
          )}
        </Box>
      ) : load ? (
        <Box
          sx={{
            p: 2,
            height: "calc(100vh - 48px)",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            pb: 8,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              color="neutral"
              size="sm"
              sx={{
                borderColor: "rgba(155,155,155,0.3)",
                textDecoration: "none !important",
              }}
              component={Link}
              to={
                location.pathname.includes(RouterConfig.activeShipments)
                  ? RouterConfig.activeShipments
                  : RouterConfig.arrivedShipments
              }
              startDecorator={<i className="fa-solid fa-chevron-left"></i>}
            >
              {t("global.back")}
            </Button>
            {load.status === "Pending" ? (
              <Button
                variant="solid"
                size="sm"
                color="warning"
                startDecorator={<i className="fa-solid fa-ban"></i>}
                onClick={() =>
                  confirm.setOpen({
                    content: `The load #${load.id} (${load.refNumber}) will be canceled. You won't be able to recover it.`,
                    onConfirm() {
                      deleteTender(load.apiTenderID)
                        .unwrap()
                        .then(() => {
                          dispatch(
                            showNotification({
                              title: `Load #${load.id} (${load.refNumber}) has been canceled.`,
                              severity: "success",
                              variant: "non-intrusive",
                              autohide: true,
                              autoHideDuration: 2000,
                            })
                          );
                          navigate(RouterConfig.activeShipments);
                        })
                        .catch(() => {
                          return;
                        });
                    },
                  })
                }
              >
                {t("Cancel Load")}
              </Button>
            ) : (
              []
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <img
                style={{ marginTop: 6 }}
                height={20}
                src={load?.getServiceLevelIcon()}
              />
              {load.reefer ? (
                <img
                  style={{ marginTop: 6 }}
                  height={20}
                  src={AppConfig.app.reeferLogo}
                />
              ) : (
                []
              )}
              <Box>
                <Typography level="title-md">
                  <b>#{load?.id}</b>
                </Typography>
                <Typography level="body-sm">({load.refNumber})</Typography>
              </Box>
            </Box>
            <img
              style={{ marginTop: 6 }}
              height={20}
              src={load?.getStatusIcon()}
            />
          </Box>
          <Divider />
          {load.lastLocation?.latitude ? <LoadLastLocation load={load} /> : []}
          <LoadDetailsStatus load={load} onStepClick={handleMarkerClick} />
          <LoadCost load={load} />
          <LoadFiles load={load} />
        </Box>
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
    </>
  );

  if (md) {
    return loadDetail;
  }

  return (
    <Allotment
      vertical={false}
      onChange={handleAllotmentChange}
      defaultSizes={customerPortalLoadAllotmentSize}
    >
      <Allotment.Pane maxSize={650} minSize={320}>
        {loadDetail}
      </Allotment.Pane>
      <Allotment.Pane>
        <NerdMap
          zoom={zoom}
          center={center}
          onZoomChange={(zoom) => setZoom(zoom)}
        >
          {markers}
        </NerdMap>
      </Allotment.Pane>
    </Allotment>
  );
}
