import { Filters } from "@nerdjs/nerd-core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Load_Entity } from "../../entities";

export interface ArchivedLoadState {
  stagedArchivedLoad: Load_Entity | undefined;
  selectedArchivedLoadID: number | undefined;
  searchOpen: boolean;
  filters?: Filters;
}

const initialState: ArchivedLoadState = {
  searchOpen: false,
  selectedArchivedLoadID: undefined,
  stagedArchivedLoad: undefined,
};

const archivedLoadSlice = createSlice({
  name: "archivedLoad",
  initialState,
  reducers: {
    setArchivedLoadSearchOpen(state, action: PayloadAction<boolean>) {
      state.searchOpen = action.payload;
    },
    setArchivedLoadFilters(state, action: PayloadAction<Filters | undefined>) {
      state.filters = action.payload;
    },
    setStagedArchivedLoad(state, action: PayloadAction<Load_Entity>) {
      state.stagedArchivedLoad = action.payload;
    },
    setSelectedArchivedLoadID(state, action: PayloadAction<number>) {
      state.selectedArchivedLoadID = action.payload;
    },
  },
});

export const {
  setArchivedLoadFilters,
  setArchivedLoadSearchOpen,
  setSelectedArchivedLoadID,
  setStagedArchivedLoad,
} = archivedLoadSlice.actions;

export default archivedLoadSlice.reducer;
