import { Alert, Button, Divider, IconButton, Typography } from "@mui/joy";
import { Box, Dialog, DialogTitle, Skeleton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { File, Load } from "../../entities";
import { AppConfig } from "../../environement";
import { useGetLoadQuery } from "../../redux/network";
import {
  selectedOpenInvoiceIDSelector,
  stagedOpenInvoiceSelector,
} from "../../redux/openInvoice/openInvoiceSelectors";
import {
  setSelectedOpenInvoiceID,
  setStagedOpenInvoice,
} from "../../redux/openInvoice/openInvoiceSlice";
import { LoadCost } from "../load/loadCost";
import { LoadDetailsStatus } from "../load/loadDetailsStatus";
import { LoadLastLocation } from "../load/loadLastLocation";

/**
 *
 * @returns {ReactElement} OpenInvoiceDetails page
 */
export function OpenInvoiceDetails() {
  const openInvoiceID = useSelector(selectedOpenInvoiceIDSelector);
  const openInvoice = useSelector(stagedOpenInvoiceSelector);
  const { data: _load } = useGetLoadQuery(openInvoice?.invoiceID, {
    skip: !openInvoice?.invoiceID,
  });
  const [pdf, setPDF] = useState<string>();
  const [mobile, setMobile] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customerPortalOpenInvoiceAllotmentSize = JSON.parse(
    localStorage.getItem("customerPortalOpenInvoiceAllotment") ??
      '["50%", "50%"]'
  );
  const [file, setFile] = useState<File>();

  useEffect(() => {
    if (openInvoice?.files?.length && !file) {
      setFile(openInvoice?.files[0]);
    }
  }, [openInvoice]);

  useEffect(() => {
    if (file) {
      fetch(
        `${AppConfig.api.endpoint}${AppConfig.api.routePrefix}/loads/${openInvoice?.invoiceID}/files/${file?.id}`,
        {
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          method: "GET",
        }
      )
        .then((response) => response.blob())
        .then((d) => {
          console.log(window.URL.createObjectURL(d));
          setPDF(window.URL.createObjectURL(d));
        });
    }
  }, [file]);

  const close = () => {
    dispatch(setSelectedOpenInvoiceID());
    dispatch(setStagedOpenInvoice());
  };

  const handleAllotmentChange = (e: number[]) => {
    if (e[1] < 600) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    localStorage.setItem(
      "customerPortalOpenInvoiceAllotment",
      JSON.stringify(e)
    );
  };

  const load = _load ? new Load(_load) : undefined;

  return (
    <Dialog
      open={Boolean(openInvoiceID)}
      fullScreen
      TransitionComponent={Transition}
      onClose={close}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            startDecorator={
              <Button
                variant="outlined"
                color="neutral"
                size="sm"
                sx={{ borderColor: "rgba(155,155,155,0.3)" }}
                onClick={close}
                startDecorator={<i className="fa-solid fa-chevron-left"></i>}
              >
                {t("global.back")}
              </Button>
            }
            level="title-md"
          >
            {t("Open Invoice")} #{openInvoice?.id}
          </Typography>
          <IconButton onClick={close} variant="soft" color="neutral" size="sm">
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          position: "relative",
        }}
      >
        <Allotment
          vertical={false}
          onChange={handleAllotmentChange}
          defaultSizes={customerPortalOpenInvoiceAllotmentSize}
        >
          <Allotment.Pane minSize={300}>
            <Box
              sx={{
                gap: 1,
                overflowX: "scroll",
                minHeight: "100%",
                alignItems: !openInvoice?.files ? "center" : undefined,
                background: "rgba(155,155,155,0.1)",
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <Box sx={{ display: "flex", gap: 1, p: 1 }}>
                {openInvoice?.files?.map((f) => (
                  <Button
                    variant="soft"
                    color={file?.id === f?.id ? "primary" : "neutral"}
                    size="sm"
                    sx={{ flexShrink: 0 }}
                    key={f.id}
                    onClick={() => setFile(f)}
                  >
                    {f.description}
                  </Button>
                ))}
              </Box>
              {!openInvoice?.files ? (
                <Box
                  sx={{ display: "flex", justifyContent: "center", flex: 1 }}
                >
                  <Typography
                    startDecorator={<i className="fa-solid fa-file"></i>}
                  >
                    No files attached to this invoice
                  </Typography>
                </Box>
              ) : (
                <iframe
                  src={pdf}
                  width="100%"
                  height="100%"
                  style={{ border: "none", flex: 1 }}
                />
              )}
            </Box>
          </Allotment.Pane>
          <Allotment.Pane minSize={400}>
            {load ? (
              <Box
                sx={{
                  p: 2,
                  height: "calc(100vh - 48px)",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <img
                      style={{ marginTop: 6 }}
                      height={20}
                      src={load?.getServiceLevelIcon()}
                    />
                    <Box>
                      <Typography level="title-md">
                        <b>#{load?.id}</b>
                      </Typography>
                      <Typography level="body-sm">
                        ({load.refNumber})
                      </Typography>
                    </Box>
                  </Box>
                  <img
                    style={{ marginTop: 6 }}
                    height={20}
                    src={load?.getStatusIcon()}
                  />
                </Box>
                <Divider />
                <Alert
                  color="warning"
                  variant="solid"
                  startDecorator={
                    <i
                      style={{ fontSize: 28 }}
                      className="fa-solid fa-comments-question"
                    ></i>
                  }
                  endDecorator={
                    <Button
                      color="warning"
                      onClick={() =>
                        window.open(
                          `mailto:invoice@whitearrow.com?Subject=Question about Invoice #${openInvoice?.id} (Load #${openInvoice?.invoiceID})`
                        )
                      }
                    >
                      invoice@whitearrow.com
                    </Button>
                  }
                >
                  <Box>
                    <Typography sx={{ color: "inherit" }} level="body-sm">
                      <b>{t("anyQuestion")}</b>
                    </Typography>
                    <Typography sx={{ color: "inherit" }} level="body-xs">
                      {t("contactAccounting")}
                    </Typography>
                  </Box>
                </Alert>
                {load.lastLocation.longitude ? (
                  <LoadLastLocation load={load} />
                ) : (
                  []
                )}

                <LoadDetailsStatus
                  load={load}
                  onStepClick={(e) => console.log(e)}
                />
                <LoadCost load={load} />
              </Box>
            ) : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </Allotment.Pane>
        </Allotment>
      </Box>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
