import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const customerPortalApi = createApi({
  reducerPath: "customerPortalApi",
  baseQuery: (args, api) => nerdNetworkBaseQuery(args, api, { service: "" }),
  endpoints: () => ({}),
  tagTypes: [
    "accessorials",
    "companies",
    "files",
    "locations",
    "openInvoices",
    "archerFiles",
    "claims",
    "contacts",
    "invoices",
    "lastLocations",
    "shipCons",
    "subscriptions",
    "subscriptionDetails",
    "activeLoads",
    "archivedLoads",
    "load",
    "quotes",
  ],
});
