import { Box } from "@mui/joy";
import { Paper } from "@mui/material";
import { ReactElement } from "react";
import { OpenInvoicesDataGrid } from "../hooks/openInvoice/openInvoiceDataGrid";
import { OpenInvoiceDetails } from "../hooks/openInvoice/openInvoiceDetails";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function OpenInvoicesListRoute(): ReactElement {
  return (
    <Box sx={{ p: 2, width: "100%", height: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          height: "calc(100vh - 146px)",
          display: "grid",
        }}
      >
        <OpenInvoicesDataGrid />
      </Paper>
      <OpenInvoiceDetails />
    </Box>
  );
}
