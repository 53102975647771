import { Filters } from "@nerdjs/nerd-core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Load_Entity } from "../../entities";

export interface ActiveLoadState {
  stagedActiveLoad: Load_Entity | undefined;
  selectedActiveLoadID: number | undefined;
  searchOpen: boolean;
  filters?: Filters;
}

const initialState: ActiveLoadState = {
  searchOpen: false,
  selectedActiveLoadID: undefined,
  stagedActiveLoad: undefined,
};

const activeLoadSlice = createSlice({
  name: "activeLoad",
  initialState,
  reducers: {
    setActiveLoadSearchOpen(state, action: PayloadAction<boolean>) {
      state.searchOpen = action.payload;
    },
    setActiveLoadFilters(state, action: PayloadAction<Filters | undefined>) {
      state.filters = action.payload;
    },
    setStagedActiveLoad(state, action: PayloadAction<Load_Entity>) {
      state.stagedActiveLoad = action.payload;
    },
    setSelectedActiveLoadID(state, action: PayloadAction<number>) {
      state.selectedActiveLoadID = action.payload;
    },
  },
});

export const {
  setActiveLoadFilters,
  setActiveLoadSearchOpen,
  setSelectedActiveLoadID,
  setStagedActiveLoad,
} = activeLoadSlice.actions;

export default activeLoadSlice.reducer;
