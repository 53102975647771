import { Filters } from "@nerdjs/nerd-core";
import { NerdAdvancedSearch, useQuery } from "@nerdjs/nerd-ui";
import { AdvancedSearchDefinition } from "@nerdjs/nerd-ui/dist/nerdAdvancedSearch/types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  archivedLoadFiltersSelector,
  archivedLoadSearchOpenSelector,
} from "../../../redux/archivedLoad/archivedLoadSelectors";
import { useAppSelector } from "../../hooks";
import {
  setArchivedLoadFilters,
  setArchivedLoadSearchOpen,
} from "../../../redux/archivedLoad/archivedLoadSlice";
import { setFormOpen } from "../../../redux/appStatus/appStatusSlice";
import { useLazyGetArchivedLoadsQuery } from "../../../redux/network";
/**
 *
 * @returns {ReactElement} LoadSearch page
 */
export function LoadSearch() {
  const dispatch = useDispatch();
  const searchOpen = useAppSelector(archivedLoadSearchOpenSelector);
  const query = useQuery();
  const filtersString = query.get("filters");
  const filters: Filters = filtersString ? JSON.parse(filtersString) : [];
  const filtersInRedux = useAppSelector(archivedLoadFiltersSelector);
  const [getArchivedLoads, { isFetching }] = useLazyGetArchivedLoadsQuery();
  const navigate = useNavigate();

  useEffect(() => {
    //We restore the filters from redux if they exists
    if (filtersInRedux && filters.length === 0) {
      query.set("filters", JSON.stringify(filtersInRedux));
      navigate({ search: query.toString() });
    }
  }, []);

  useEffect(() => {
    // we save the filters in redux whenever they change
    if (filtersString) {
      const filters = JSON.parse(filtersString);
      dispatch(setArchivedLoadFilters(filters));
    } else dispatch(setArchivedLoadFilters(undefined));
  }, [filtersString]);

  const definition: AdvancedSearchDefinition[] = [
    {
      id: "id",
      name: "loads.id",
      label: "ID",
      type: "int",
    },
    {
      id: "reference_number",
      name: "loads.reference_number",
      label: "Reference Number",
      type: "string",
      faIcon: "fa-regular fa-hashtag",
    },
    {
      id: "quote_id",
      name: "loads.quote_id",
      label: "Quote ID",
      type: "int",
      faIcon: "fa-regular fa-file-invoice-dollar",
    },
    {
      id: "invoice_date",
      name: "loads.invoice_date",
      label: "Invoice Date",
      type: "date",
    },
  ];

  return (
    <NerdAdvancedSearch
      definitions={definition}
      open={searchOpen}
      setOpen={(o) => dispatch(setArchivedLoadSearchOpen(o))}
      hidePresets
      itemsPerPage={25}
      onRefresh={(q) => {
        getArchivedLoads(q);
      }}
      refreshPending={isFetching}
    />
  );
}
