import { createSelector } from "@reduxjs/toolkit";
import { Load } from "../../entities";
import { RootState } from "../store";

const selectActiveLoadFilters = (state: RootState) =>
  state.activeLoadState.filters;
const selectActiveLoadSearchOpen = (state: RootState) =>
  state.activeLoadState.searchOpen;
const selectSelectedActiveLoadID = (state: RootState) =>
  state.activeLoadState.selectedActiveLoadID;
const selectStageActiveLoadSelector = (state: RootState) =>
  state.activeLoadState.stagedActiveLoad;

export const stagedActiveLoadSelector = createSelector(
  [selectStageActiveLoadSelector],
  (s) => (s ? new Load(s) : s)
);

export const selectedActiveLoadIDSelector = createSelector(
  [selectSelectedActiveLoadID],
  (s) => s
);

export const activeLoadSearchOpenSelector = createSelector(
  [selectActiveLoadSearchOpen],
  (s) => s
);

export const activeLoadFiltersSelector = createSelector(
  [selectActiveLoadFilters],
  (s) => s
);
