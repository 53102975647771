import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { ArcherFile_Entity } from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const archerFilesAdapter = createEntityAdapter<ArcherFile_Entity>();
const archerFilesInitialState = archerFilesAdapter.getInitialState();

export const archerFileEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getArcherFiles: build.query<
      EntityState<ArcherFile_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `archerFiles`,
          params: args,
        };
      },
      serializeQueryArgs: ({ endpointDefinition, endpointName }) =>
        defaultSerializeQueryArgs({
          queryArgs: {},
          endpointDefinition,
          endpointName,
        }),
      transformResponse: (responseData: ArcherFile_Entity[]) => {
        return archerFilesAdapter.setAll(archerFilesInitialState, responseData);
      },
      providesTags: ["archerFiles"],
    }),
    getArcherFile: build.query<ArcherFile_Entity, number>({
      query: (id) => `archerFiles/${id}`,
    }),
    createArcherFile: build.mutation<
      ArcherFile_Entity,
      Partial<ArcherFile_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `archerFiles`,
      }),
      invalidatesTags: ["archerFiles"],
    }),
    updateArcherFile: build.mutation<
      void,
      { id: number; body: Partial<ArcherFile_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `archerFiles/${args.id}`,
      }),
      invalidatesTags: ["archerFiles"],
    }),
    deleteArcherFile: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `archerFiles/${id}`,
      }),
      invalidatesTags: ["archerFiles"],
    }),
    getArcherFileSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `archerFiles/searchMetadata`,
    }),
  }),
});

export const {
  useGetArcherFileQuery,
  useGetArcherFilesQuery,
  useGetArcherFileSearchMetadataQuery,
  useLazyGetArcherFilesQuery,
  useLazyGetArcherFileQuery,
  useLazyGetArcherFileSearchMetadataQuery,
  useCreateArcherFileMutation,
  useDeleteArcherFileMutation,
  useUpdateArcherFileMutation,
} = archerFileEndpoints;

export default archerFileEndpoints;

export const selectArcherFilesResult =
  archerFileEndpoints.endpoints.getArcherFiles.select();

const selectArcherFilesData = createSelector(
  selectArcherFilesResult,
  (archerFilesResult) => {
    return archerFilesResult.data;
  }
);

export const {
  selectAll: selectAllArcherFiles,
  selectById: selectArcherFileById,
} = archerFilesAdapter.getSelectors<RootState>((state) => {
  return selectArcherFilesData(state) ?? archerFilesInitialState;
});
