import { Chip, Grid, Sheet, Tooltip, Typography, useTheme } from "@mui/joy";
import { Box, useMediaQuery } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { NerdPaginationFooter, useQuery } from "@nerdjs/nerd-ui";
import { DateTime } from "luxon";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Load, Load_Entity } from "../../../entities";
import { AppConfig } from "../../../environement";
import { setStagedActiveLoad } from "../../../redux/activeLoad/activeLoadSlice";
import {
  getActiveLoadsSelectors,
  useGetActiveLoadsQuery,
} from "../../../redux/network";
import { RouterConfig } from "../../config/routerConfig";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { LoadSearch } from "./activeLoadSearch";

const DATA_GRID_STATE_KEY = "customer-portal-active-loads-datagrid-state";

/**
 *
 * @returns {ReactElement} ActiveLoadsDataGrid component
 */
export function ActiveLoadsDataGrid() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const query = useQuery();
  const filters = query.get("filters");
  const limit = query.get("limit");
  const offset = query.get("offset");
  const { t } = useTranslation("entities");
  const [key, setKey] = useState(0);
  const params = {
    filters: filters,
    offset: offset !== null ? offset : 0,
    limit: limit !== null ? limit : 25,
  };
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const selectAllActiveLoads = getActiveLoadsSelectors(params).selectAll;
  const { isFetching, data: activeLoads } = useGetActiveLoadsQuery(params);
  const columns: GridColDef<Load_Entity>[] = useMemo(
    () => [
      {
        field: "refNumber",
        width: 140,
        headerName: t("load.attributes.refNumber"),
        renderCell(params) {
          const load = params.row;
          if (load.id) {
            return (
              <div>
                <Typography
                  startDecorator={
                    <>
                      <Tooltip arrow title={params.row.serviceLevel}>
                        <img
                          src={new Load(load).getServiceLevelIcon()}
                          height={16}
                        />
                      </Tooltip>
                      {params.row.reefer && (
                        <img
                          src={AppConfig.app.reeferLogo}
                          width={18}
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </>
                  }
                  level="body-sm"
                >
                  <b>{`#${load.id}`}</b>
                </Typography>
                <Typography level="body-xs">
                  {`(${t("load.others.ref")}: ${load.refNumber})`}
                </Typography>
              </div>
            );
          } else return params.value;
        },
      },
      {
        field: "status",
        headerName: t("load.attributes.status"),
        width: 150,
        valueGetter(params) {
          if (params.row.id) return new Load(params.row).getStatusIcon();
          else return "";
        },
        groupingValueGetter(params) {
          if (params.row.id) return new Load(params.row).getStatusIcon();
          else return "";
        },
        renderCell(params) {
          if (params.value) return <img src={params.value} width={140} />;
          else return params.value;
        },
      },
      {
        field: "lastLocation",
        headerName: "Last Location",
        width: 220,
        valueGetter(params) {
          if (params.rowNode.type !== "group")
            return params.row.lastLocation.city;
          else return "";
        },
        groupingValueGetter(params) {
          return params.row.lastLocation.city;
        },
        renderCell(params) {
          if (params.row.lastLocation) {
            const lastLocation = params.row.lastLocation;
            return (
              <>
                <Typography
                  noWrap
                  level="body-sm"
                >{`${lastLocation.city} ${lastLocation.state}`}</Typography>
              </>
            );
          } else return params.value;
        },
      },
      {
        field: "loadPickup",
        headerName: t("load.others.load_pickup"),
        valueGetter(params) {
          if (params.rowNode.type !== "group")
            return new Load(params.row).getPickupDateTime();
          else return params.value;
        },
        groupingValueGetter(params) {
          return new Load(params.row).getPickupDateTime();
        },
        renderCell(params) {
          if (params.rowNode.type !== "group") {
            const load = new Load(params.row);
            const dateTime = load.getPickupDateTime();
            return (
              <Typography display="block" level="body-sm" noWrap>
                {dateTime}
              </Typography>
            );
          } else return params.value;
        },
        width: 250,
      },
      {
        field: "pickupETA",
        headerName: "Pickup In/Out",
        width: 250,
        valueGetter(params) {
          if (params.rowNode.type !== "group") {
            const pickup = new Load(params.row).getPickup();
            return `${pickup?.eta.arrivedAt}/${pickup?.eta.departedAt}`;
          } else return undefined;
        },
        groupingValueGetter(params) {
          const pickup = new Load(params.row).getPickup();
          return `${pickup?.eta.arrivedAt}/${pickup?.eta.departedAt}`;
        },
        renderCell(
          params: GridRenderCellParams<Load_Entity, string | undefined>
        ) {
          const [arrivedAt, departedAt] = params.value
            ? params.value.split("/")
            : ["undefined", "undefined"];
          return (
            <Grid container>
              <Grid xs={12}>
                {arrivedAt !== "undefined" ? (
                  <Grid xs={6}>
                    <Typography noWrap level="body-sm" display={"inline"}>
                      <b>{"IN: "}</b>
                    </Typography>
                    <Typography noWrap level="body-sm" display={"inline"}>
                      {DateTime.fromISO(arrivedAt).toUTC().toFormat("ff")}
                    </Typography>
                  </Grid>
                ) : null}
                {departedAt !== "undefined" ? (
                  <Grid xs={6}>
                    <Typography noWrap level="body-sm" display={"inline"}>
                      <b>{"OUT: "}</b>
                    </Typography>
                    <Typography noWrap level="body-sm" display={"inline"}>
                      {DateTime.fromISO(departedAt).toUTC().toFormat("ff")}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          );
        },
      },
      {
        field: "pickupCity",
        headerName: t("load.others.pickup_city"),
        align: "center",
        width: 200,
        valueGetter(params) {
          if (params.rowNode.type !== "group") {
            const location = new Load(params.row).getPickupLocation();
            return location.city;
          } else return "";
        },
        groupingValueGetter(params) {
          const location = new Load(params.row).getPickupLocation();
          return location.city;
        },
        renderCell(params) {
          if (params.rowNode.type !== "group") {
            const location = new Load(params.row).getPickupLocation();
            return (
              <Box sx={{ width: "100%" }}>
                <Typography noWrap level="body-sm">
                  {location.name}
                </Typography>
                <Typography
                  level="body-sm"
                  noWrap
                >{`${location.city}, ${location.state}`}</Typography>
              </Box>
            );
          } else return params.value;
        },
      },
      {
        field: "deliveryCity",
        headerName: t("load.others.delivery_city"),
        align: "center",
        width: 200,
        valueGetter(params) {
          if (params.rowNode.type !== "group") {
            const location = new Load(params.row).getDeliveryLocation();
            return location.city;
          } else return "";
        },
        groupingValueGetter(params) {
          const location = new Load(params.row).getDeliveryLocation();
          return location.city;
        },
        renderCell(params) {
          if (params.rowNode.type !== "group") {
            const location = new Load(params.row).getDeliveryLocation();
            return (
              <Box sx={{ width: "100%" }}>
                <Typography noWrap level="body-sm">
                  {location.name}
                </Typography>
                <Typography
                  noWrap
                  level="body-sm"
                >{`${location.city}, ${location.state}`}</Typography>
              </Box>
            );
          } else return params.value;
        },
      },
      {
        field: "deliveryETA",
        width: 250,
        headerName: "Delivery In/Out",
        valueGetter(params) {
          if (params.rowNode.type !== "group") {
            const delivery = new Load(params.row).getDelivery();
            return `${delivery?.eta.arrivedAt}/${delivery?.eta.departedAt}`;
          } else return undefined;
        },
        groupingValueGetter(params) {
          const delivery = new Load(params.row).getDelivery();
          return `${delivery?.eta.arrivedAt}/${delivery?.eta.departedAt}`;
        },
        renderCell(
          params: GridRenderCellParams<Load_Entity, string | undefined>
        ) {
          const [arrivedAt, departedAt] = params.value
            ? params.value.split("/")
            : ["undefined", "undefined"];
          return (
            <Grid container>
              <Grid xs={12}>
                {arrivedAt !== "undefined" ? (
                  <Grid xs={6}>
                    <Typography noWrap level="body-sm" display={"inline"}>
                      <b>{"IN: "}</b>
                    </Typography>
                    <Typography noWrap level="body-sm" display={"inline"}>
                      {DateTime.fromISO(arrivedAt).toUTC().toFormat("ff")}
                    </Typography>
                  </Grid>
                ) : null}
                {departedAt !== "undefined" ? (
                  <Grid xs={6}>
                    <Typography noWrap level="body-sm" display={"inline"}>
                      <b>{"OUT: "}</b>
                    </Typography>
                    <Typography noWrap level="body-sm" display={"inline"}>
                      {DateTime.fromISO(departedAt).toUTC().toFormat("ff")}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          );
        },
      },
      {
        field: "loadDelivery",
        width: 250,
        headerName: t("load.others.load_delivery"),
        valueGetter(params) {
          if (params.rowNode.type !== "group")
            return new Load(params.row).getDeliveryDateTime();
          else return params.value;
        },
        groupingValueGetter(params) {
          return new Load(params.row).getDeliveryDateTime();
        },
        renderCell(params) {
          if (params.rowNode.type !== "group") {
            const load = new Load(params.row);
            const dateTime = load.getDeliveryDateTime();
            const _deliveryDate = load.getDeliveryDate();
            const deliveryDate = _deliveryDate
              ? DateTime.fromFormat(_deliveryDate, "yyyy-MM-dd")
              : undefined;
            const now = DateTime.now();
            return (
              <Box sx={{ width: "100%" }}>
                <Typography noWrap level="body-sm">
                  {dateTime}
                </Typography>
                {
                  // eslint-disable-next-line i18next/no-literal-string
                  deliveryDate && deliveryDate.hasSame(now, "day") ? (
                    <>
                      <Typography noWrap level="body-sm" display={"inline"}>
                        {`${t("load.others.eta")}: `}
                      </Typography>
                      <Chip size="sm" color="success">
                        {t("load.others.available")}
                      </Chip>
                    </>
                  ) : null
                }
              </Box>
            );
          } else return params.value;
        },
      },
    ],
    []
  );
  const rows = useAppSelector((state) => selectAllActiveLoads(state));
  const columnsState = localStorage.getItem(DATA_GRID_STATE_KEY);
  const initialState: GridInitialStatePremium = useMemo(() => {
    try {
      return columnsState
        ? JSON.parse(columnsState)
        : {
            pinnedColumns: isDownMd
              ? {}
              : { left: ["refNumber"], right: ["status"] },
          };
    } catch {
      return {
        pinnedColumns: isDownMd
          ? {}
          : { left: ["refNumber"], right: ["status"] },
      };
    }
  }, [columnsState]);

  const resetTableSettings = () => {
    localStorage.removeItem(DATA_GRID_STATE_KEY);
    setKey((k) => k + 1);
  };

  const saveState = useCallback(() => {
    localStorage.setItem(
      DATA_GRID_STATE_KEY,
      JSON.stringify(apiRef.current.exportState())
    );
  }, [apiRef]);

  return (
    <>
      <DataGridPremium
        key={key}
        density="standard"
        initialState={initialState}
        onStateChange={saveState}
        rows={rows}
        loading={isFetching}
        columns={columns}
        apiRef={apiRef}
        getCellClassName={(p) => (!p.isEditable ? "read-only" : "")}
        slots={{
          toolbar: CustomToolbar,
          footer: CustomFooter,
        }}
        slotProps={{
          toolbar: {
            resetTableSettings,
          },
          footer: { isFetching, count: rows.length },
        }}
        onRowSelectionModelChange={(selectionModel) => {
          const load = activeLoads?.entities[selectionModel[0]];
          if (load) {
            dispatch(setStagedActiveLoad(load));
            navigate(`${RouterConfig.activeShipments}/${selectionModel[0]}`);
          }
        }}
      />
    </>
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar({
  resetTableSettings,
}: {
  resetTableSettings: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Sheet>
      <Grid container p={0.5} gap={0.5}>
        <Grid xs={12} display={"flex"} justifyContent={"end"}>
          <Chip
            color="neutral"
            size="sm"
            startDecorator={<i className="fa-solid fa-rotate-right"></i>}
            onClick={resetTableSettings}
          >
            {t("Reset Columns Layout")}
          </Chip>
        </Grid>
        <Grid xs={12}>
          <LoadSearch />
        </Grid>
      </Grid>
    </Sheet>
  );
}

/**
 *
 * @param {object} props Component props
 * @param {boolean} props.isFetching is DataGrid loading
 * @param {number} props.count Rows number
 * @returns {ReactElement} Custom Footer
 */
function CustomFooter(props: {
  isFetching: boolean;
  count: number;
}): ReactElement {
  const { isFetching, count } = props;
  return <NerdPaginationFooter count={count} loading={isFetching} />;
}
