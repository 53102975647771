import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { Accessorial_Entity } from "../../../entities";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";

const accessorialsAdapter = createEntityAdapter<Accessorial_Entity>();
const accessorialsInitialState = accessorialsAdapter.getInitialState();

export const accessorialEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getAccessorials: build.query<
      EntityState<Accessorial_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `accessorials`,
          params: args,
        };
      },
      serializeQueryArgs: ({ endpointDefinition, endpointName }) =>
        defaultSerializeQueryArgs({
          queryArgs: {},
          endpointDefinition,
          endpointName,
        }),
      transformResponse: (responseData: Accessorial_Entity[]) => {
        return accessorialsAdapter.setAll(
          accessorialsInitialState,
          responseData
        );
      },
      providesTags: ["accessorials"],
    }),
    getAccessorial: build.query<Accessorial_Entity, number>({
      query: (id) => `accessorials/${id}`,
    }),
    createAccessorial: build.mutation<
      Accessorial_Entity,
      Partial<Accessorial_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `accessorials`,
      }),
      invalidatesTags: ["accessorials"],
    }),
    updateAccessorial: build.mutation<
      void,
      { id: number; body: Partial<Accessorial_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `accessorials/${args.id}`,
      }),
      invalidatesTags: ["accessorials"],
    }),
    deleteAccessorial: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `accessorials/${id}`,
      }),
      invalidatesTags: ["accessorials"],
    }),
    getAccessorialSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `accessorials/searchMetadata`,
    }),
  }),
});

export const {
  useGetAccessorialQuery,
  useGetAccessorialsQuery,
  useGetAccessorialSearchMetadataQuery,
  useLazyGetAccessorialsQuery,
  useLazyGetAccessorialQuery,
  useLazyGetAccessorialSearchMetadataQuery,
  useCreateAccessorialMutation,
  useDeleteAccessorialMutation,
  useUpdateAccessorialMutation,
} = accessorialEndpoints;

export default accessorialEndpoints;

export const selectAccessorialsResult =
  accessorialEndpoints.endpoints.getAccessorials.select();

const selectAccessorialsData = createSelector(
  selectAccessorialsResult,
  (accessorialsResult) => {
    return accessorialsResult.data;
  }
);

export const {
  selectAll: selectAllAccessorials,
  selectById: selectAccessorialById,
} = accessorialsAdapter.getSelectors<RootState>((state) => {
  return selectAccessorialsData(state) ?? accessorialsInitialState;
});
