import { Box, CircularProgress } from "@mui/joy";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { LoadDetails } from "../hooks/load/loadDetails";
import { useGetLoadQuery } from "../redux/network";

/**
 *
 * @returns {ReactElement | null} ArchivedLoadDetailsRoute
 */
export function ArchivedLoadDetailsRoute(): ReactElement | null {
  const { loadID } = useParams();
  if (loadID !== undefined) {
    const { data: load, isFetching } = useGetLoadQuery(Number(loadID));
    if (load) return <LoadDetails load={load} />;
    else if (isFetching)
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    else return null;
  } else return null;
}
