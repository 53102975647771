import { Box } from "@mui/joy";
import { TenderPage } from "@nerdjs/sales-kit";
import { useNavigate } from "react-router-dom";
import { RouterConfig } from "../hooks/config/routerConfig";

/**
 *
 * @returns {ReactElement} Tender page
 */
export function TenderRoute() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TenderPage
        customerPortal={true}
        newQuote={() => navigate(RouterConfig.quote)}
        openLoad={(loadId) =>
          navigate(`${RouterConfig.activeShipments}/${loadId}`)
        }
      />
    </Box>
  );
}
