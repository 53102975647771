import { Card, Divider, Grid, Sheet, Typography } from "@mui/joy";
import { Box, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LastLocation_Entity, Load, Load_Entity } from "../../entities";

export type LoadDetailsStatusProps = {
  onStepClick: (lat: number, lng: number) => void;
} & conditionalTypes;

type conditionalTypes =
  | {
      anonymous: true;
      load: LastLocation_Entity;
    }
  | {
      anonymous?: false;
      load: Load_Entity;
    };

/**
 * @param {LoadDetailsStatusProps} props Component props
 * @returns {ReactElement} LoadDetailsStatus
 */
export function LoadDetailsStatus(props: LoadDetailsStatusProps): ReactElement {
  const { load: _load, onStepClick, anonymous } = props;
  const { t } = useTranslation("entities");
  const theme = useTheme();
  const [load] = useMemo(
    () =>
      anonymous
        ? []
        : [
            new Load(_load),
            _load.stops?.filter((stop) => stop.type == "pickup"),
            _load.stops?.filter((stop) => stop.type == "delivery"),
          ],
    [_load]
  );

  return (
    <Card
      sx={{
        p: 2,
        zIndex: theme.zIndex.modal - 1,
      }}
    >
      <Typography level="title-md">{t("Load Details")}</Typography>
      <Grid
        container
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        sx={{ pt: 2 }}
      >
        <Grid xs={12}>
          {load?.stops?.map((s, i) => (
            <Box
              onClick={() => {
                onStepClick(Number(s?.latitude), Number(s?.longitude));
              }}
              key={s.stopNumber}
              sx={{ display: "flex", gap: 1, alignItems: "stretch" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  {s.type === "pickup" ? (
                    <i className="fa-solid fa-arrow-up"></i>
                  ) : (
                    ""
                  )}
                  {s.type === "delivery" ? (
                    <i className="fa-solid fa-arrow-down"></i>
                  ) : (
                    ""
                  )}
                </Typography>
              </Box>
              <Box>
                <Sheet
                  variant="solid"
                  color={s.eta?.departed ? "success" : "neutral"}
                  sx={{
                    height: "100%",
                    width: 12,
                    borderTopRightRadius: i === 0 ? 6 : 0,
                    borderTopLeftRadius: i === 0 ? 6 : 0,
                    borderBottomRightRadius:
                      i === load?.stops?.length - 1 ? 6 : 0,
                    borderBottomLeftRadius:
                      i === load?.stops?.length - 1 ? 6 : 0,
                    fontSize: 7,
                    p: 0.25,
                    textAlign: "center",
                  }}
                >
                  <i className="fa-solid fa-circle"></i>
                </Sheet>
              </Box>
              <Box
                sx={{
                  pb: 4,
                  p: 1,
                  width: "100%",
                  borderRadius: 4,
                  cursor: "pointer",
                  background: "rgba(155,155,155,0)",
                  transition: "background .1s",
                  "&:hover": {
                    background: "rgba(155,155,155,0.1)",
                  },
                }}
              >
                <Typography level="body-sm">
                  <b>
                    {s.stopNumber}. {s?.name}
                  </b>
                </Typography>
                <Typography level="body-xs">
                  {s.zip} {s.city}, {s.state}
                </Typography>
                <Divider />
                <Typography level="body-xs">
                  {t("Appointment:")}{" "}
                  {s.date ? DateTime.fromISO(s.date).toFormat("MM/dd/yy") : ""}{" "}
                  {s.timeFrom
                    ? `${DateTime.fromISO(s.timeFrom).toFormat("hh:mm a")} - ${
                        s.timeTo
                          ? DateTime.fromISO(s.timeTo).toFormat("hh:mm a")
                          : ""
                      }`
                    : ""}
                </Typography>
                {s.eta?.arrivedAt ? (
                  <Typography level="body-xs">
                    {t("Arrived At:")}{" "}
                    {DateTime.fromISO(s.eta?.arrivedAt.slice(0, -1)).toFormat(
                      "MM/dd/yy hh:mm a"
                    )}{" "}
                    ({t("Local Time")})
                  </Typography>
                ) : (
                  []
                )}
                {s.eta?.departedAt ? (
                  <Typography level="body-xs">
                    {t("Departed At:")}{" "}
                    {DateTime.fromISO(s.eta?.departedAt.slice(0, -1)).toFormat(
                      "MM/dd/yy hh:mm a"
                    )}{" "}
                    ({t("Local Time")})
                  </Typography>
                ) : (
                  []
                )}
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
}
