import { Download } from "@mui/icons-material";
import {
  Badge,
  Chip,
  Grid,
  IconButton,
  Sheet,
  Typography,
  useColorScheme,
  useTheme,
} from "@mui/joy";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { NerdPaginationFooter, useQuery } from "@nerdjs/nerd-ui";
import { DateTime } from "luxon";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Invoice, Invoice_Entity } from "../../entities";
import { AppConfig } from "../../environement";
import {
  getOpenInvoicesSelectors,
  useGetOpenInvoicesQuery,
} from "../../redux/network";
import { useAppSelector } from "../hooks";
import { getFileIcon } from "../load/utils";
import { OpenInvoiceSearch } from "./openInvoiceSearch";
import { useDispatch } from "react-redux";
import {
  setSelectedOpenInvoiceID,
  setStagedOpenInvoice,
} from "../../redux/openInvoice/openInvoiceSlice";
import { currencyFormatter } from "../../helpers/accounting/accounting";

declare module "@mui/x-data-grid-premium" {
  interface FooterPropsOverrides {
    isFetching: boolean;
    count: number;
  }
}

const DATA_GRID_STATE_KEY = "customer-portal-accounting-datagrid-state";

/**
 *
 * @returns {ReactElement} OpenInvoicesDataGrid component
 */
export function OpenInvoicesDataGrid(): ReactElement {
  const { mode } = useColorScheme();
  const apiRef = useGridApiRef();
  const query = useQuery();
  const { t } = useTranslation("entities");
  const [key, setKey] = useState(0);
  const filters = query.get("filters");
  const limit = query.get("limit");
  const offset = query.get("offset");
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const params = {
    filters: filters,
    offset: offset !== null ? offset : 0,
    limit: limit !== null ? limit : 25,
  };
  const selectAllOpenInvoices = getOpenInvoicesSelectors(params).selectAll;
  const { isFetching } = useGetOpenInvoicesQuery(params);
  const columns: GridColDef<Invoice_Entity>[] = useMemo(
    () => [
      {
        field: "referenceNumber",
        width: 140,
        headerName: t("load.attributes.refNumber"),
        renderCell(params) {
          if (params.rowNode.type !== "group") {
            const load = params.row;
            return (
              <div>
                <Typography level="body-sm">
                  <b>{`#${load.invoiceID}`}</b>
                </Typography>
                <Typography level="body-xs">
                  {`(${t("load.others.ref")}: ${load.referenceNumber})`}
                </Typography>
              </div>
            );
          } else return params.value;
        },
      },
      {
        field: "id",
        headerName: t("Invoice Number"),
        type: "string",
        width: 150,
        valueFormatter(params) {
          return params.value ? `#${params.value}` : "";
        },
        groupingValueGetter(params) {
          return params.value ? `#${params.value}` : "";
        },
      },
      {
        field: "invoiceDate",
        headerName: t("invoice.attributes.invoiceDate"),
        width: 150,
        valueFormatter(params) {
          return params.value
            ? DateTime.fromISO(params.value).toFormat("DD") || ""
            : "";
        },
        groupingValueGetter(params) {
          return params.value
            ? DateTime.fromISO(params.value).toFormat("DD") || ""
            : "";
        },
      },
      {
        field: "dueDate",
        headerName: t("invoice.attributes.dueDate"),
        width: 150,
        valueFormatter(params) {
          return params.value
            ? DateTime.fromISO(params.value).toFormat("DD") || ""
            : "";
        },
        groupingValueGetter(params) {
          return params.value
            ? DateTime.fromISO(params.value).toFormat("DD") || ""
            : "";
        },
      },
      {
        field: "days",
        headerName: t("invoice.others.age"),
        type: "number",
      },
      {
        field: "moneyDue",
        headerName: t("invoice.attributes.moneyDue"),
        type: "number",
        width: 100,
        align: "right",
        valueFormatter(params) {
          return params.value ? currencyFormatter.format(params.value) : "";
        },
        groupingValueGetter(params) {
          return params.value ? currencyFormatter.format(params.value) : "";
        },
      },
      {
        field: "status",
        headerName: t("invoice.attributes.status"),
        width: 100,
        align: "right",
        type: "number",
        flex: 1,
        renderCell(params) {
          if (params.value)
            switch (params.value) {
              case "Good Standing":
                return (
                  <Chip size="sm" color={"success"}>
                    {params.value}
                  </Chip>
                );
              case "Late 30":
                return (
                  <Chip
                    size="sm"
                    color={"success"}
                    sx={{
                      backgroundColor: mode == "light" ? "#f0f5d7" : "#181d00",
                      color: mode == "light" ? "#4f5d0f" : "#cdec77",
                    }}
                  >
                    {params.value}
                  </Chip>
                );
              case "Late 60":
                return (
                  <Chip size="sm" color={"warning"}>
                    {params.value}
                  </Chip>
                );
              case "Late 90":
                return (
                  <Chip size="sm" color={"danger"}>
                    {params.value}
                  </Chip>
                );
              default:
                return <Chip size="sm">{params.value}</Chip>;
            }
          else return "";
        },
      },
      {
        field: "downloadFiles",
        headerName: "",
        filterable: false,
        groupable: false,
        aggregable: false,
        renderCell(params) {
          if (params.rowNode.type !== "group") {
            const invoice = new Invoice(params.row);
            return <DownloadFile invoice={invoice} />;
          } else return "";
        },
      },
    ],
    []
  );
  const rows = useAppSelector((state) => selectAllOpenInvoices(state));
  const columnsState = localStorage.getItem(DATA_GRID_STATE_KEY);
  const initialState: GridInitialStatePremium = useMemo(() => {
    try {
      return columnsState
        ? JSON.parse(columnsState)
        : {
            pinnedColumns: isDownMd
              ? {}
              : {
                  right: ["moneyDue", "downloadFiles"],
                  left: ["referenceNumber"],
                },
          };
    } catch {
      return {
        pinnedColumns: isDownMd
          ? {}
          : {
              right: ["moneyDue", "downloadFiles"],
              left: ["referenceNumber"],
            },
      };
    }
  }, [columnsState]);
  const resetTableSettings = () => {
    localStorage.removeItem(DATA_GRID_STATE_KEY);
    setKey((k) => k + 1);
  };

  const saveState = useCallback(() => {
    localStorage.setItem(
      DATA_GRID_STATE_KEY,
      JSON.stringify(apiRef.current.exportState())
    );
  }, [apiRef]);

  return (
    <DataGridPremium
      key={key}
      initialState={initialState}
      onStateChange={saveState}
      onRowClick={(r) => {
        dispatch(setSelectedOpenInvoiceID(r.row.id));
        dispatch(setStagedOpenInvoice(r.row));
      }}
      rows={rows}
      loading={isFetching}
      columns={columns}
      apiRef={apiRef}
      getCellClassName={(p) => (!p.isEditable ? "read-only" : "")}
      slots={{
        toolbar: CustomToolbar,
        footer: CustomFooter,
      }}
      slotProps={{
        toolbar: { resetTableSettings },
        footer: { isFetching, count: rows.length },
      }}
    />
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar({
  resetTableSettings,
}: {
  resetTableSettings: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Sheet>
      <Grid container p={0.5} gap={0.5}>
        <Grid xs={12} display={"flex"} justifyContent={"end"}>
          <Chip
            color="neutral"
            size="sm"
            startDecorator={<i className="fa-solid fa-rotate-right"></i>}
            onClick={resetTableSettings}
          >
            {t("Reset Columns Layout")}
          </Chip>
        </Grid>
        <Grid xs={12}>
          <OpenInvoiceSearch />
        </Grid>
      </Grid>
    </Sheet>
  );
}

/**
 *
 * @param {object} props Component props
 * @param {boolean} props.isFetching is DataGrid loading
 * @param {number} props.count Rows number
 * @returns {ReactElement} Custom Footer
 */
function CustomFooter(props: { isFetching: boolean; count: number }) {
  const { count, isFetching } = props;
  return <NerdPaginationFooter count={count} loading={isFetching} />;
}

/**
 *
 * @param {object} props props
 * @param {Invoice} props.invoice invoice
 * @returns {ReactElement} DownloadFile
 */
function DownloadFile(props: { invoice: Invoice }) {
  const { invoice } = props;
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLAnchorElement) | null
  >(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Badge
        badgeContent={invoice.files?.length}
        size="sm"
        sx={{ fontSize: 10 }}
        color="danger"
        badgeInset="8%"
      >
        <IconButton
          size="sm"
          color="neutral"
          variant="outlined"
          onClick={handleClick}
        >
          <Download />
        </IconButton>
      </Badge>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {invoice.files?.map((file) => (
          <MenuItem
            key={file.id}
            onClick={() => {
              const a = encodeURI(
                `${AppConfig.api.endpoint}${AppConfig.api.routePrefix}/loads/${invoice.invoiceID}/files/${file.id}`
              );
              window.open(a, "_blank", "noopener,noreferrer");
              handleClose();
            }}
          >
            <ListItemIcon>{getFileIcon(file.description)}</ListItemIcon>
            <ListItemText>{file.description}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
