import { NerdAppBarProps } from "@nerdjs/nerd-ui";
import { NerdAppBarDarkModeButton } from "../nerdAppBarDarkModeButton/nerdAppBarDarkModeButton";
import { NerdUserMenu } from "../nerdUserMenu/nerdUserMenu";
import { QuickTrack } from "../quickTrack/quickTrack";
import { Chip, Typography } from "@mui/joy";
import { Box, Hidden } from "@mui/material";
import { AppConfig } from "../../environement";
import { NerdImpersonate } from "../nerdImpersonate/nerdImpersonate";

/**
 * @param {"dark" | "light" | "system"} mode App Theme
 * @returns {NerdAppBarProps} NerdAppBarProps
 */
export function appBarConfig(
  mode: "dark" | "light" | "system" = "light",
  isDownSm: boolean
): NerdAppBarProps {
  let logo =
    mode === "light"
      ? "https://storage.googleapis.com/archer-public/assets/wa-pba.png"
      : "https://storage.googleapis.com/archer-public/assets/wa-pba-white.png";
  if (isDownSm)
    logo = "https://storage.googleapis.com/archer-public/assets/wa.png";
  return {
    title: "",
    sx: {},
    logo,
    divider: false,
    content: [
      <Hidden smDown key="beta">
        {AppConfig.app.beta ? (
          <Chip
            sx={{ border: "solid 1px rgba(155,155,155,0.3)", fontSize: 11 }}
            size="sm"
            color="neutral"
            variant="soft"
          >
            BETA
          </Chip>
        ) : (
          <Box />
        )}
      </Hidden>,
      <Typography key="version" level="body-sm">
        v{window.app_info?.tag}
      </Typography>,
      <div style={{ flex: 1 }} key={"nerdAppMenustylingDiv2"} />,
      <QuickTrack key={"nerdAppMenuQuickTrack"} />,
      <div style={{ flex: 1 }} key={"nerdAppMenustylingDiv"} />,
      <NerdAppBarDarkModeButton key={"nerdAppBarDarkModeButton"} />,
      <NerdUserMenu key={"myAccountButton"} />,
      <NerdImpersonate key={"NerdImpersonate"} />,
    ],
  };
}
