import { Box } from "@mui/joy";
import { ReactElement } from "react";
import { ActiveLoadsDataGrid } from "../hooks/load/active/activeLoadsDataGrid";
import { Paper } from "@mui/material";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function ActiveLoadsListRoute(): ReactElement {
  return (
    <Box sx={{ p: 2, width: "100%", height: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          height: "calc(100vh - 146px)",
          display: "grid",
        }}
      >
        <ActiveLoadsDataGrid />
      </Paper>
    </Box>
  );
}
