import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { NerdNavigationBar } from "@nerdjs/nerd-ui";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppConfig } from "../../environement";
import { RouterConfig } from "../config/routerConfig";

/**
 *
 * @component
 * @example
 * <MainDrawer/>
 * @returns {ReactElement | null} MainDrawer component
 */
export function MainMenu(): ReactElement | null {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { t } = useTranslation();

  return (
    <NerdNavigationBar
      navigationBarIndex={1}
      chipVariant="plain"
      selectedChipVariant="solid"
      rightItems={[
        {
          title: t("drawer.apiDocumentation"),
          selected: false,
          startDecorator: (
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          ),
          action: () => {
            window.open(
              "https://white-arrow.gitbook.io/white-arrow-api-documentation/",
              "blank"
            );
          },
        },
      ]}
      leftItems={
        currentUser
          ? [
              {
                title: t("drawer.new_quote"),
                startDecorator: <i className="fa-solid fa-dollar-sign"></i>,
                to: RouterConfig.quote,
              },
              {
                title: t("drawer.active_shipments"),
                startDecorator: <i className="fa-solid fa-box"></i>,
                to: RouterConfig.activeShipments,
              },
              {
                title: t("drawer.arrived_shipments"),
                startDecorator: <i className="fa-solid fa-flag-checkered"></i>,
                to: RouterConfig.arrivedShipments,
              },
              {
                title: t("drawer.quotes"),
                startDecorator: (
                  <i className="fa-solid fa-clock-rotate-left"></i>
                ),
                to: RouterConfig.quotes,
              },
              {
                title: t("drawer.accounting"),
                startDecorator: (
                  <i className="fa-solid fa-building-columns"></i>
                ),
                to: RouterConfig.accounting,
              },
              {
                title: t("drawer.contacts"),
                startDecorator: <i className="fa-solid fa-address-book"></i>,
                to: RouterConfig.contacts,
              },
            ]
          : [
              {
                title: t("Sign in"),
                selected: true,
                startDecorator: <i className="fa-solid fa-circle-user"></i>,
                action: () => {
                  window.open(AppConfig.front["401Redirect"], "blank");
                },
              },
            ]
      }
    />
  );
}
