import { DateTime } from "luxon";
import { ShipCon, ShipCon_Entity } from "../shipCon";
import Load_Entity from "./load";
import { AppConfig } from "../../environement";
export default class Load extends Load_Entity {
  static fromList(loadsJSON: unknown): Array<Load> {
    const loads: Load[] = [];
    if (loadsJSON)
      Array.isArray(loadsJSON) &&
        loadsJSON.forEach((loadJSON) => {
          loads.push(new Load(loadJSON));
        });
    return loads;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  private getStopLocation(stopType: "pickup" | "delivery"): {
    city: string;
    state: string;
    name: string;
  } {
    const stop = this.stops?.find((stop) => stop.type === stopType);
    const location: { city: string; state: string; name: string } = {
      city: "",
      state: "",
      name: "",
    };
    if (stop) {
      location.city = stop.city;
      location.name = stop.name;
      location.state = stop.state;
    }
    return location;
  }

  private getStopTime(stopType: "pickup" | "delivery"): string {
    const stop = this.stops?.find((stop) => stop.type === stopType);
    if (stop) return new ShipCon(stop).getTime();
    else return "";
  }

  getServiceLevelIcon(): string {
    if (this.type === "LTL") {
      if (this.serviceLevel === "guaranteed")
        return "https://storage.googleapis.com/archer-public/service-levels/wa_LTL%20G.png";
      if (this.serviceLevel === "premium")
        return "https://storage.googleapis.com/archer-public/service-levels/wa_LTL%20P.png";
      if (this.serviceLevel === "economy")
        return "https://storage.googleapis.com/archer-public/service-levels/wa_LTL%20E.png";
    }

    if (this.type === "TL") {
      if (this.serviceLevel === "guaranteed")
        return "https://storage.googleapis.com/archer-public/service-levels/wa_RDD%20G.png";
      if (this.serviceLevel === "premium")
        return "https://storage.googleapis.com/archer-public/service-levels/wa_RDD%20G.png";
      if (this.serviceLevel === "economy")
        return "https://storage.googleapis.com/archer-public/service-levels/wa_IMDL.png";
    }
    return "";
  }

  getStatusIcon(): string {
    const url =
      this.status === "Pending"
        ? AppConfig.app.loadStatusLogo.pending
        : this.status === "En Route"
        ? AppConfig.app.loadStatusLogo.enRoute
        : this.status === "Dropped"
        ? AppConfig.app.loadStatusLogo.dropped
        : this.status === "Processing"
        ? AppConfig.app.loadStatusLogo.processing
        : this.status === "To Delivery"
        ? AppConfig.app.loadStatusLogo.toDelivery
        : this.status === "To Pickup"
        ? AppConfig.app.loadStatusLogo.toPickup
        : this.status === "Delivered"
        ? AppConfig.app.loadStatusLogo.delivered
        : this.status === "Cancelled"
        ? AppConfig.app.loadStatusLogo.cancelled
        : "";

    return url;
  }

  private getStopDateTime(stopType: "pickup" | "delivery"): string {
    const stop = this.stops?.find((stop) => stop.type === stopType);
    if (!stop) return "";
    let dateTime = "";
    if (stop) {
      const time = this.getStopTime(stopType);
      if (stop.date) {
        dateTime = DateTime.fromFormat(stop.date, "yyyy-LL-dd").toFormat("DD");
        if (time) {
          dateTime = dateTime + ", " + time;
        }
      }
    }
    return dateTime;
  }

  getPickupDateTime(): string {
    return this.getStopDateTime("pickup");
  }

  getPickupLocation(): { city: string; state: string; name: string } {
    return this.getStopLocation("pickup");
  }

  getDeliveryDateTime(): string {
    return this.getStopDateTime("delivery");
  }

  getDeliveryLocation(): { city: string; state: string; name: string } {
    return this.getStopLocation("delivery");
  }

  getDeliveryDate(): string | undefined {
    const deliveryStop = this.getDelivery();
    return deliveryStop?.date;
  }

  getDeliveryTime(): string | undefined {
    return this.getStopTime("delivery");
  }

  getPickupDate(): string | undefined {
    const pickupStop = this.getPickup();
    return pickupStop?.date;
  }

  getPickupTime(): string | undefined {
    return this.getStopTime("pickup");
  }

  getPickup(): ShipCon_Entity | undefined {
    return this.stops?.find((stop) => stop.type === "pickup");
  }

  getDelivery(): ShipCon_Entity | undefined {
    return this.stops?.find((stop) => stop.type === "delivery");
  }
}
