import { accountKitApi, accountKitReducer } from "@nerdjs/account-kit";
import { nerdNetworkReducer, rtkQueryErrorLogger } from "@nerdjs/nerd-network";
import { nerdUIReducer } from "@nerdjs/nerd-ui";
import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { mainMiddleware } from "./mainMiddleware";
import activeLoadReducer from "./activeLoad/activeLoadSlice";
import archivedLoadReducer from "./archivedLoad/archivedLoadSlice";
import appStatusReducer from "./appStatus/appStatusSlice";
import openInvoiceReducer from "./openInvoice/openInvoiceSlice";
import { customerPortalApi } from "./network";
import { salesApi, salesKitReducer } from "@nerdjs/sales-kit";

const rootReducer = combineReducers({
  salesKitState: salesKitReducer,
  accountKitState: accountKitReducer,
  nerdUIState: nerdUIReducer,
  networkState: nerdNetworkReducer,
  activeLoadState: activeLoadReducer,
  archivedLoadState: archivedLoadReducer,
  appStatusState: appStatusReducer,
  openInvoiceState: openInvoiceReducer,
  [accountKitApi.reducerPath]: accountKitApi.reducer,
  [customerPortalApi.reducerPath]: customerPortalApi.reducer,
  [salesApi.reducerPath]: salesApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunkMiddleware,
    mainMiddleware,
    accountKitApi.middleware,
    customerPortalApi.middleware,
    salesApi.middleware,
    rtkQueryErrorLogger,
  ],
});

export type AppDispatch = typeof store.dispatch;
