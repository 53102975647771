import {
  AccountBox,
  Business,
  CardMembership,
  PowerSettingsNew,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  NerdChangeUserOrg,
  useSignOutMutation,
  useGetCurrentUserQuery,
  User,
} from "@nerdjs/account-kit";
import { useTranslation } from "react-i18next";
import { RouterConfig } from "../config/routerConfig";
import { StyledMenu } from "@nerdjs/nerd-ui";
import { Button, IconButton, Typography } from "@mui/joy";
import { ReactElement, useState } from "react";
import { Link } from "react-router-dom";

/**
 * @component
 * @returns {ReactElement} UserDisplay component
 */
export function NerdUserMenu(): ReactElement | null {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data: currentUser } = useGetCurrentUserQuery();
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? "basic-menu" : undefined;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [signoutMutation] = useSignOutMutation();

  /**
   * Disconnect user
   */
  function signout() {
    signoutMutation();
  }

  if (!currentUser) return null;
  else
    return (
      <>
        {md ? (
          <Button
            size="sm"
            color="neutral"
            variant="plain"
            onClick={handleClick}
            startDecorator={<AccountBox fontSize="small" />}
          >
            {t("global.my_account")}
          </Button>
        ) : (
          <Tooltip title={`${t("entities:app.entity")}`}>
            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              onClick={handleClick}
            >
              <i className="fa-solid fa-user"></i>
            </IconButton>
          </Tooltip>
        )}
        <StyledMenu
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
        >
          <MenuList>
            <Grid
              container
              justifyContent={"center"}
              textAlign="center"
              spacing={1}
              mb={2}
            >
              <Grid item xs={12}>
                {currentUser?.icon ? (
                  <Avatar
                    src={currentUser.icon}
                    sx={{ width: 75, height: 75 }}
                  />
                ) : (
                  <Avatar sx={{ width: 75, height: 75, margin: "auto" }} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>{new User(currentUser).fullname()}</Typography>
                <Typography level="body-sm">{currentUser?.email}</Typography>
              </Grid>
              {currentUser && (
                <Grid item xs={12}>
                  <NerdChangeUserOrg />
                </Grid>
              )}
            </Grid>
            <Divider
              sx={{
                mb: 1,
              }}
            />
            <MenuItem
              onClick={() => {
                handleClose();
              }}
              component={Link}
              to={`${RouterConfig.myAccount}?index=0`}
            >
              <ListItemIcon>
                <CardMembership />
              </ListItemIcon>
              <ListItemText>{t("entities:user.others.my_info")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
              }}
              component={Link}
              to={`${RouterConfig.myAccount}?index=1`}
            >
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText>
                {t("entities:user.others.my_company")}
              </ListItemText>
            </MenuItem>
            <Divider />
            {/* <MenuItem
              onClick={() => {
                dispatch(setOpenFeedbackDialog({ open: true }));
                handleClose();
              }}
            >
              <ListItemIcon>
                <i className="fa-regular fa-message-lines"></i>
              </ListItemIcon>
              <ListItemText>Feedback</ListItemText>
            </MenuItem>
            <Divider /> */}
            <MenuItem onClick={signout}>
              <ListItemIcon>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText>{t("global.signout")}</ListItemText>
            </MenuItem>
          </MenuList>
        </StyledMenu>
      </>
    );
}
