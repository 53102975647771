/**
 * Quote_Entity type guard.
 *
 * @param {any} quoteJson Quote object from API
 * @returns {boolean} Return true if type is Quote_Entity
 */
function validator(quoteJson: unknown): quoteJson is Quote_Entity {
  if (typeof quoteJson === "object" && quoteJson != null) {
    Quote_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(quoteJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class Quote_Entity {
  static requiredFields = [
    "id",
    "customerId",
    "customerName",
    "createdAt",
    "commodityDescription",
    "commodityDescriptionId",
    "totalMiles",
    "pallets",
    "pieces",
    "freightClass",
    "dryVanOnly",
    "feet",
    "type",
    "stops",
    "serviceLevels",
    "accessorials",
    "units",
    "api",
    "version",
  ];

  constructor(quoteJson: unknown) {
    if (validator(quoteJson)) {
      this.id = quoteJson.id;
      this.loadId = quoteJson.loadId;
      this.customerId = quoteJson.customerId;
      this.customerName = quoteJson.customerName;
      this.createdAt = quoteJson.createdAt;
      this.commodityDescription = quoteJson.commodityDescription;
      this.commodityDescriptionId = quoteJson.commodityDescriptionId;
      this.totalMiles = quoteJson.totalMiles;
      this.pallets = quoteJson.pallets;
      this.pieces = quoteJson.pieces;
      this.temp = quoteJson.temp;
      this.reeferCont = quoteJson.reeferCont;
      this.freightClass = quoteJson.freightClass;
      this.dryVanOnly = quoteJson.dryVanOnly;
      this.feet = quoteJson.feet;
      this.weight = quoteJson.weight;
      this.height = quoteJson.height;
      this.type = quoteJson.type;
      this.stops = quoteJson.stops;
      this.transitTime = quoteJson.transitTime;
      this.serviceLevels = quoteJson.serviceLevels;
      this.accessorials = quoteJson.accessorials;
      this.units = quoteJson.units;
      this.api = quoteJson.api;
      this.version = quoteJson.version;
    } else {
      throw new Error(`Failed to create new instance of ${Quote_Entity.name}`);
    }
  }

  id: number;
  loadId?: number;
  customerId: number;
  customerName: string;
  createdAt: string;
  commodityDescription: string;
  commodityDescriptionId: number;
  totalMiles: number;
  pallets: number;
  pieces: number;
  temp?: string;
  reeferCont?: boolean;
  freightClass: string;
  dryVanOnly: boolean;
  feet: number;
  weight: number;
  height?: number;
  type: string;
  stops: Array<Stop_Entity>;
  transitTime: number;
  serviceLevels: Array<QuoteServiceLevel_Entity>;
  accessorials: Array<QuoteAccessorials_Entity>;
  units: Units_Entity;
  api: string;
  version: number;
}

export type Stop_Entity = {
  id: number;
  name: string;
  referenceNumber: string;
  type: string;
  date: string;
  timeFrom: string;
  timeTo: string;
  city: string;
  state: string;
  zip: string;
  address: Address_Entity;
};

export type Address_Entity = {
  locationId?: number;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  latitude: number;
  longitude: number;
};

export type QuoteServiceLevel_Entity = {
  id: string;
  description: string;
  serviceLevel: string;
  name: string;
  baseRate: number;
  extra: number;
  fuelRate: number;
  totalRate: number;
  flexible: boolean;
  surcharges: Array<QuoteAccessorials_Entity>;
  from: string;
  to: string;
};

export type QuoteAccessorials_Entity = {
  costAmount: number;
  name: string;
  accessorialId: number;
  stopId: number;
  stopName: string;
};

export type Units_Entity = {
  palletDimensions: Array<Dimensions_Entity>;
  totalDimensions: TotalDimension_Entity;
};

export type Dimensions_Entity = {
  units: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  count: number;
  pieces: number;
  class: string;
  stackable: boolean;
};

export type TotalDimension_Entity = {
  linearFeet?: number;
  totalWeight: string;
};
