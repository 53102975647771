import { Wrapper as GoogleMapsWrapper } from "@googlemaps/react-wrapper";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import * as Sentry from "@sentry/react";
import { Settings } from "luxon";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import NerdApp from "./hooks/nerdApp/nerdApp";
import "./i18n";
import i18n from "./i18n";
import "./index.css";
import { store } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import {
  CssVarsProvider as JoyCssVarsProvider,
  extendTheme,
} from "@mui/joy/styles";

const materialTheme = materialExtendTheme();

Settings.defaultLocale = "en-US";

// document.title = AppConfig.app.title || "Nerd Home";
// const favIcon = document.getElementById("favicon");
// if (favIcon !== null && favIcon instanceof HTMLLinkElement)
//   favIcon.href = AppConfig.app.favicon || "";

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://dac3a92c798c4c62a64fe55a383a23f7@o4505109909602304.ingest.sentry.io/4505439717752832",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://portal.whitearrow.com/"],
      }),
      new Sentry.Replay({ maskAllText: false }),
    ],
    normalizeDepth: 10,
    ignoreErrors: [
      "TypeError: Load failed",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  Sentry.setTags({
    repo: window.app_info?.repo,
    tag: window.app_info?.tag,
    commit: window.app_info?.commit,
  });
}

//Mui X Pro License Key
LicenseInfo.setLicenseKey(
  "2fe41b67b6ffd3f20b04b3b21b7682c6Tz03NTE3NCxFPTE3MjY5Mzc4NTQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const container = document.getElementById("root");
const theme = extendTheme();

if (container != null) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <GoogleMapsWrapper
            apiKey={"AIzaSyD67kHvhZC_mBkppkJIMAkZDu4c279J_As"}
            libraries={["places"]}
          >
            <MaterialCssVarsProvider
              theme={{ [MATERIAL_THEME_ID]: materialTheme }}
            >
              <JoyCssVarsProvider theme={{ ...theme, direction: "ltr" } as any}>
                <NerdApp />
              </JoyCssVarsProvider>
            </MaterialCssVarsProvider>
          </GoogleMapsWrapper>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
