import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { customerPortalApi } from "../api/customerPortalApi";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { RootState } from "../types";
import Quote_Entity from "../../../entities/quote/quote";

const quotesAdapter = createEntityAdapter<Quote_Entity>();
const quotesInitialState = quotesAdapter.getInitialState();

export const quoteEndpoints = customerPortalApi.injectEndpoints({
  endpoints: (build) => ({
    getQuotes: build.query<
      EntityState<Quote_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `quotes`,
          params: args,
        };
      },
      transformResponse: (responseData: Quote_Entity[]) => {
        return quotesAdapter.setAll(quotesInitialState, responseData);
      },
      providesTags: ["quotes"],
    }),
    getQuote: build.query<Quote_Entity, number>({
      query: (id) => `quotes/${id}`,
    }),
    createQuote: build.mutation<Quote_Entity, Partial<Quote_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `quotes`,
      }),
      invalidatesTags: ["quotes"],
    }),
    updateQuote: build.mutation<
      void,
      { id: number; body: Partial<Quote_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `quotes/${args.id}`,
      }),
      invalidatesTags: ["quotes"],
    }),
    deleteQuote: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `quotes/${id}`,
      }),
      invalidatesTags: ["quotes"],
    }),
    getQuoteSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `quotes/searchMetadata`,
    }),
  }),
});

export const {
  useGetQuoteQuery,
  useGetQuotesQuery,
  useGetQuoteSearchMetadataQuery,
  useLazyGetQuotesQuery,
  useLazyGetQuoteQuery,
  useLazyGetQuoteSearchMetadataQuery,
  useCreateQuoteMutation,
  useDeleteQuoteMutation,
  useUpdateQuoteMutation,
} = quoteEndpoints;

export default quoteEndpoints;

export const getQuotesSelectors = (params: Record<string, unknown> | void) => {
  return quotesAdapter.getSelectors<RootState>((state) => {
    return (
      createSelector(
        quoteEndpoints.endpoints.getQuotes.select(params),
        (quotesResult) => {
          return quotesResult.data;
        }
      )(state) ?? quotesInitialState
    );
  });
};
