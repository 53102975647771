export const RouterConfig = {
  root: "/",
  activeShipments: "/active-shipments",
  quote: "/quote",
  arrivedShipments: "/arrived-shipments",
  quickTrack: "/quicktrack",
  myAccount: "/myAccount",
  contacts: "/contacts",
  accounting: "/accounting",
  quotes: "/quotes",
  tender: "/tender",
};
